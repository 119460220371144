import styled from 'styled-components'
import NavigationItem from './NavigationItem'
import NavigationLabel from './NavigationLabel'
import NavigationLink from './NavigationLink'
import NavigationItemsList from './NavigationItemsList'

export default styled('li')`
  margin: .3rem 0;

  ${NavigationItemsList} {
    margin-left: calc(24px + 1rem);

    ${NavigationLabel} {
      font-weight: normal;
    }
  }

  ${NavigationLabel} {
    font-weight: bold;
  }
`
