import React from 'react'
import { getVendors, Vendor } from '../../../api/vendorApi'
import { getActiveAccountId } from '../../../selectors/envSelectors'
import { AppState } from '../../../redux/reducers'
import { connect } from 'react-redux'
import { AxiosResponse } from 'axios'
import SelectField, { SelectFieldProps } from './SelectField'

interface VendorSelectFieldState {
  vendors: Vendor[]
}

interface VendorSelectFieldProps extends SelectFieldProps {
  accountId: string
}

class VendorSelectField extends React.Component<VendorSelectFieldProps, VendorSelectFieldState> {
  constructor(props: VendorSelectFieldProps) {
    super(props)
    this.state = { vendors: [] }
  }

  componentDidMount = () => {    
    if (this.props.accountId) {
      getVendors(this.props.accountId)
        .then((res: AxiosResponse) => {
          this.setState({ vendors: res.data.items })
        })
    }
  }

  render = () => {
    if (this.state.vendors.length < 1) {
      return 'Loading vendors...'
    }

    return (
      <SelectField
        {...this.props}
        options={this.state.vendors.map((vendor) => ({label: vendor.name, value: vendor.id}))}
      />
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(VendorSelectField)
