import { AxiosPromise } from 'axios'
import { get } from '.'

export interface Account {
  id: string
  name: string
  label: string
  domain: string
  modules: string[]
}

export const getAccounts = (): AxiosPromise<Account[]> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts`,
})
