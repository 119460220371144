import { AxiosPromise } from 'axios'
import { get, post } from '.'

export interface OrderProduct {
  exposition_id?: string
  id: string
  name: string
  period_id?: string
  price: number
  quantity: number
  type: string
}

export interface Log {
  message: string
  created_at: string
}

export interface Order {
  id: string
  created_at: string
  customer: {
    email: string
    first_name: string
    last_name: string
    phone?: string
    newsletter: boolean
  }
  logs: Log[]
  order_number: string
  payment_id: string
  payment: {
    amount: number
    method: string
    issuer: string
    status: number
  },
  products: OrderProduct[]
}

export interface GetOrdersResponse {
  items: Order[]
  total: number
}

export const getOrders = (accountId: string, params: object = {}): AxiosPromise<GetOrdersResponse> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/orders`,
  params
})

export const getOrder = (accountId: string, orderId: string): AxiosPromise<Order> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/orders/${orderId}`
})

export const postOrderSuccess = (accountId: string, orderId: string) => post({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/orders/${orderId}/success`,
})
