import styled from 'styled-components';

interface ShimmerRectProps {
  width: number;
  height: number;
  margin: string;
}

const ShimmerRect = styled.div<ShimmerRectProps>`
  animation: shimmerFrames 1.15s forwards infinite linear;
  background-color: rgb(212, 212, 219);
  background-image: linear-gradient(90deg, rgb(212, 212, 219) 0px, rgb(251, 251, 252) 20%, rgb(212, 212, 219) 40%, rgb(212, 212, 219));
  background-size: 468px 32px;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  display:block;
  width: ${(props) => `${props.width}px` || '100%'};
  height: ${(props) => `${props.height}px` || '100%'};
  margin: ${(props) => props.margin || '0 1rem 1rem 0'};
`;

export default ShimmerRect;
