import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Product, getProduct } from '../api/productApi'
import { AppState } from '../redux/reducers'
import { getActiveAccountId } from '../selectors/envSelectors'
import FormSkeleton from '../components/layout/FormSkeleton';
import PageHeader from '../components/layout/PageHeader';
import Alert, { AlertType } from '../components/alerts/Alert';
import ProductForm from '../components/form/forms/ProductForm';

interface RouteParams {
  id: string;
}

interface AccountProductsDetailProps extends RouteComponentProps<RouteParams> {
  accountId: string | null;
}

interface AccountProductsDetailState {
  product?: Product;
  success: boolean
}

class AccountProductsDetail extends Component<AccountProductsDetailProps, AccountProductsDetailState> {
  state: AccountProductsDetailState = {
    success: false
  }

  componentDidMount = () => {
    this.fetchProduct()
  }

  componentDidUpdate = (prevProps: AccountProductsDetailProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.fetchProduct();
    }
  }

  fetchProduct = () => {
    if (typeof this.props.match.params.id !== 'undefined' && this.props.accountId) {
      getProduct(this.props.accountId, this.props.match.params.id)
        .then((res) => {
          this.setState({ product: res.data })
        })
    }
  }

  render = () => {
    if (typeof this.state.product === 'undefined') {
      return <FormSkeleton />
    }

    return (
      <div>
        {this.state.success && (
          <Alert type={AlertType.SUCCESS}>
            Update successful
          </Alert>
        )}
        <PageHeader>      
          {this.state.product.name}
        </PageHeader>
        <ProductForm
          product={this.state.product}
          onSaved={
            (product) => {
              this.setState(
                { product, success: true },
                () => setTimeout(() => this.setState({ success: false }), 2000)
              )
            }
          }
        />
        {this.state.success && (
          <Alert type={AlertType.SUCCESS}>
            Update successful
          </Alert>
        )}
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(AccountProductsDetail)
