import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  borderColor: {
    default: '#d7dce4',
  },
  borderRadius: {
    default: '4px',
  },
  color: {
    neutralDark: '#161629',
    neutralMedium: '#235BCD',
    neutralLight: '#e9ebf2',
    primary: '#f09938',
    secondary: '',
    success: '#28a745',
    error: '#dc3545',
    warning: '#f09938',
    lightGrey: '#e0e0e0b3',
  },
  textColor: {
    default: '#161629',
    muted: '#808080',
  },
  margin: {
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
  },
  padding: {
    sm: '0.25rem',
    md: '1rem',
    lg: '2rem',
  },
};
