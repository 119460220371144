import React from 'react';
import styled from 'styled-components';

interface ModalHeaderProps {
  hide: () => void;
  title: string;
}

const Header = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.padding.md};
  background-color: ${({ theme }) => theme.color.neutralDark};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const ModalTitle = styled.h3`
  color: #fff;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 999px;
  border: none;
  background-color: #fff;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ModalHeader: React.FC<ModalHeaderProps> = ({ hide, title }) => (
  <Header>
    <CloseButton onClick={hide}>&times;</CloseButton>
    <ModalTitle>{title}</ModalTitle>
  </Header>
);

export default ModalHeader;
