import { AxiosPromise } from 'axios';
import { get, put, post } from '.';
import { Product } from './productApi';
import { Offer } from './offerApi';
import { ImageSizes } from './fileApi';
import { BasketInfoLine } from '../components/form/subforms/BasketForm';

export type PageType =
  | 'dierentuintickets_default'
  | 'order_confirmation'
  | 'checkout'
  | 'rcx_exposition'
  | 'dierentuintickets_home'
  | 'dierentuintickets_how_to'
  | 'dierentuintickets_cookie_declaration';

export interface Page {
  background_image?: ImageSizes;
  description: string;
  explanation?: string;
  exposition_id?: string;
  id: string;
  locale: string;
  offers?: Offer[];
  products?: Product[];
  activities?: string[];
  slug: string;
  title: string;
  name: string;
  type: PageType;
  deleted_at: Date;
  checkout_warning_message: string;
  basketInfoLines: BasketInfoLine[];

  indexable: boolean;
  meta_description?: string;
  meta_title?: string;
}

export const getPages = (accountId: string): AxiosPromise<Page[]> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/pages`,
  });

export const getPage = (
  accountId: string,
  pageId: string
): AxiosPromise<Page> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/pages/${pageId}`,
  });

export const postPage = (accountId: string, data: object): AxiosPromise<Page> =>
  post({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/pages`,
    data,
  });

export const putPage = (
  accountId: string,
  pageId: string,
  data: object
): AxiosPromise<Page> =>
  put({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/pages/${pageId}`,
    data,
  });
