import React from 'react'
import { getActiveAccountId } from '../../../selectors/envSelectors'
import { AppState } from '../../../redux/reducers'
import { connect } from 'react-redux'
import { AxiosResponse } from 'axios'
import SelectField, { SelectFieldProps } from './SelectField'
import { getOffers, Offer } from '../../../api/offerApi'

interface OfferSelectFieldState {
  offers: Offer[]
}

interface OfferSelectFieldProps extends SelectFieldProps {
  accountId: string
}

class OfferSelectField extends React.Component<OfferSelectFieldProps, OfferSelectFieldState> {
  constructor(props: OfferSelectFieldProps) {
    super(props)
    this.state = { offers: [] }
  }

  componentDidMount = () => {    
    if (this.props.accountId) {
      getOffers(this.props.accountId)
        .then((res: AxiosResponse) => {
          this.setState({ offers: res.data.items })
        })
    }
  }

  render = () => {
    if (this.state.offers.length < 1) {
      return 'Loading offers...'
    }

    return (
      <SelectField
        {...this.props}
        options={this.state.offers.map((offer) => ({ label: offer.name, value: offer.id }))}
      />
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(OfferSelectField)
