import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import FormGroup from '../FormGroup'
import { AppState } from '../../../redux/reducers'
import { getActiveAccountId } from '../../../selectors/envSelectors'
import Hr from '../../typography/Hr'
import ImageUploadField from '../fields/ImageUploadField'
import { postVendor, putVendor, Vendor } from '../../../api/vendorApi'
import TextField from '../fields/TextField'
import CheckboxField from '../fields/CheckboxField'
import RichTextField from '../fields/RichTextField'
import FieldSet from '../fields/FieldSet'
import SubmitButton from '../../buttons/SubmitButton'
import Row from '../../layout/Row'
import Col from '../../layout/Col'
import SeoSubform from '../subforms/SeoSubform'
import TextareaField from '../fields/TextareaField'

const vendorSchema = Yup.object({
  name: Yup.string().required(),
  active: Yup.boolean().required(),
  main_image: Yup.string().required(),
  background_image: Yup.string().required(),
  logo: Yup.string().required(),
  logo_secondary: Yup.string().required(),
  location: Yup.string().required(),
  description: Yup.string().required(),
  short_description: Yup.string().required(),
  meta_description: Yup.string(),
  gallery: Yup.array().of(Yup.string()),
  contact_information: Yup.string().required(),
  slug: Yup.string().required(),
})

interface VendorFormValues {
  name: string
  active: boolean
  main_image: string
  background_image: string
  logo: string
  logo_secondary: string
  location: string
  description: string
  short_description: string
  gallery: string[]
  contact_information: string
  slug: string

  indexable: boolean
  meta_description: string
  meta_title: string
}

interface VendorFormProps {
  accountId: string
  onSaved: (vendor: Vendor) => void
  vendor?: Vendor
}

interface VendorFormState {
  loading: boolean;
}

class VendorForm extends React.Component<VendorFormProps, VendorFormState> {
  state = {
    loading: true,
  }

  isEdit = () => typeof this.props.vendor !== 'undefined'

  render = () => {
    const { accountId, onSaved, vendor } = this.props
    return (
      <Formik
        initialValues={{
          name: typeof vendor !== 'undefined' ? vendor.name : '',
          description: vendor?.description || '',
          short_description: vendor?.short_description || '',
          contact_information: typeof vendor !== 'undefined' ? vendor.contact_information : '',
          logo: typeof vendor !== 'undefined' ? vendor.logo : '',
          logo_secondary: typeof vendor !== 'undefined' ? vendor.logo_secondary : '',
          active: typeof vendor !== 'undefined' ? vendor.active : true,
          slug: typeof vendor !== 'undefined' ? vendor.slug : '',
          location: typeof vendor !== 'undefined' ? vendor.location : '',
          gallery: (typeof vendor !== 'undefined' && Array.isArray(vendor.gallery))
            ? vendor.gallery.map((item) => item.thumbnail)
            : [],
          main_image: vendor?.main_image?.thumbnail || '',
          background_image: vendor?.background_image?.thumbnail || '',
          indexable: vendor?.indexable || true,
          meta_description: vendor?.meta_description || '',
          meta_title: vendor?.meta_title || ''
        }}
        onSubmit={(values: VendorFormValues, formikHelpers: FormikHelpers<VendorFormValues>) => {
          if (typeof vendor !== 'undefined') {
            return putVendor(accountId, vendor.id, values)
              .then((response) => {
                formikHelpers.setSubmitting(false)
                onSaved(response.data)
              })
          }
          
          return postVendor(accountId, values)
            .then((response) => {
              formikHelpers.setSubmitting(false)
              onSaved(response.data)
            })
        }}
        validationSchema={vendorSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row>
              <Col md={6}>
                <FieldSet
                  name="active"
                  label="Active"
                  helplabel="Inactive vendors will not be shown on any public pages anymore."
                  component={CheckboxField}
                />
                <FieldSet
                  name="name"
                  label="Name"
                  helplabel="Name is also displayed as a title on vendor page."
                  component={TextField}
                />
                <FieldSet
                  name="slug"
                  label="Slug"
                  helplabel={`
                    The part after the domain where your page will be accessible on.
                    E.g. https://domain.tld/your/custom/slug. Must start with a '/'.
                  `}
                  component={TextField}
                />
                <FieldSet
                  name="location"
                  label="Location"
                  helplabel="The location shown on the vendor page, this must be a city name only."
                  component={RichTextField}
                />
                <FieldSet
                  name="description"
                  label="Description"
                  helplabel="The description shown on the vendor page."
                  component={RichTextField}
                />
                <FieldSet
                  name="short_description"
                  label="Short Description"
                  helplabel="A simplified description. Used on vendor cards on list views."
                  component={TextareaField}
                />
                <FieldSet
                  name="contact_information"
                  label="Travel description"
                  helplabel="Route information on how to travel to vendors location."
                  component={RichTextField}
                />
                <FieldSet
                  name="logo"
                  label="Logo"
                  helplabel="The main logo for this vendor"
                  component={ImageUploadField}
                />
                <FieldSet
                  name="logo_secondary"
                  label="Secondary logo"
                  helplabel="An additional logo for this vendor"
                  component={ImageUploadField}
                />
                <FieldSet
                  name="main_image"
                  label="Main image"
                  helplabel="This is the vendors' main image."
                  component={ImageUploadField}
                />
                <FieldSet
                  name="background_image"
                  label="Background Image"
                  helplabel="The image that will be used as a background on vendor pages"
                  component={ImageUploadField}
                />
                <FieldSet
                  name="gallery"
                  label="Gallery"
                  helplabel="A collection of images that is being displayed as a gallery"
                  component={ImageUploadField}
                  multiple={true}
                />
              </Col>
              <Col md={6}>
                <SeoSubform />
              </Col>
            </Row>
            
            <Hr />
            <FormGroup>
              <SubmitButton isSubmitting={isSubmitting}>
                Save
              </SubmitButton>
            </FormGroup>
          </Form>
        )}
      </Formik>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(VendorForm)
