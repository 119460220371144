import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Account } from '../../api/accountApi'
import { AppState } from '../../redux/reducers'
import { ThunkDispatch } from '../../redux/types'
import { fetchAccounts, setActiveAccount } from '../../actions/accountActions'
import NavigationItemsList from './NavigationItemsList'
import NavigationItemGroup from './NavigationItemGroup'
import NavigationLink from './NavigationLink'
import NavigationItem from './NavigationItem'
import { getActiveAccount } from '../../selectors/envSelectors'
import modules from '../../constants/modules'
import { DashboardIcon, SettingsIcon, PageIcon, ProductIcon, OfferIcon, VendorIcon, OrderIcon } from '../icons'

const Nav = styled.nav`
  position: fixed;
  height: calc(100% - 5.6rem);
  display: flex;
  top: 5.6rem;
  width: 24rem;
  padding: 2rem;
  border-right: 1px solid ${({ theme }) => theme.borderColor.default};
  flex-direction: column;

  a, a:visited {
  }
`

const NavFontIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  font-size: 2.4rem;
`

interface NavigationProps extends RouteComponentProps {
  account: Account | null
  accounts: Account[];
  activeAccountName: string | null;
  getAccounts: () => void;
  switchAccount: (accountId: string) => void;
}

const NavigationItemsListMain = styled(NavigationItemsList)`
  flex: 1;
`

class Navigation extends React.Component<NavigationProps, {}> {
  componentDidMount = () => {
    this.props.getAccounts();
  }

  switchAccount = (account: string) => {
    this.props.switchAccount(account);
    this.props.history.push(`/${account}`);
  }

  render = () => {
    const { account } = this.props
    if (!account) {
      return <Nav />
    }
    return (
      <Nav>
        <NavigationItemsListMain>
          <NavigationItemGroup>
            <NavigationLink
              to={`/${this.props.activeAccountName || 'beeksebergen'}/`}
              icon={<NavFontIcon><DashboardIcon /></NavFontIcon>}
            >
              Dashboard
            </NavigationLink>
          </NavigationItemGroup>
          {account.modules.includes(modules.PAGE) && (
            <NavigationItemGroup>
              <NavigationLink
                to={`/${this.props.activeAccountName || 'beeksebergen'}/pages`}
                icon={<NavFontIcon><PageIcon /></NavFontIcon>}
              >
                Pages
              </NavigationLink>
            </NavigationItemGroup>
          )}
          {account.modules.includes(modules.PRODUCT) && (
            <NavigationItemGroup>
              <NavigationLink
                to={`/${this.props.activeAccountName || 'beeksebergen'}/products`}
                icon={<NavFontIcon><ProductIcon /></NavFontIcon>}
              >
                Products
              </NavigationLink>
            </NavigationItemGroup>
          )}
          {account.modules.includes(modules.ORDER) && (
            <NavigationItemGroup>
              <NavigationLink
                to={`/${this.props.activeAccountName || 'beeksebergen'}/orders`}
                icon={<NavFontIcon><OrderIcon /></NavFontIcon>}
              >
                Orders
              </NavigationLink>
            </NavigationItemGroup>
          )}
          {account.modules.includes(modules.VENDOR) && (
            <NavigationItemGroup>
              <NavigationLink
                to={`/${this.props.activeAccountName || 'beeksebergen'}/vendors`}
                icon={<NavFontIcon><VendorIcon /></NavFontIcon>}
              >
                Vendors
              </NavigationLink>
            </NavigationItemGroup>
          )}
          {account.modules.includes(modules.OFFER) && (
            <NavigationItemGroup>
              <NavigationLink
                to={`/${this.props.activeAccountName || 'beeksebergen'}/offers`}
                icon={<NavFontIcon><OfferIcon /></NavFontIcon>}
              >
                Offers
              </NavigationLink>
            </NavigationItemGroup>
          )}
        </NavigationItemsListMain>
        <NavigationItemsList>
          <NavigationItemGroup>
            <NavigationLink
              to="/admin"
              icon={<NavFontIcon><SettingsIcon /></NavFontIcon>}
            >
              Settings
            </NavigationLink>
            {this.props.match.url.includes('/admin') && (
              <NavigationItemsList>
                <NavigationItem>
                  <NavigationLink to="/admin/accounts">Accounts</NavigationLink>
                </NavigationItem>
              </NavigationItemsList>
            )}
          </NavigationItemGroup>
        </NavigationItemsList>
      </Nav>
    )
  }
}

const mapState = (state: AppState) => ({
  account: getActiveAccount(state),
  accounts: Object.values(state.accounts),
  activeAccountName: state.env.account,
})

const mapDispatch = (dispatch: ThunkDispatch) => ({
  getAccounts: () => dispatch(fetchAccounts()),
  switchAccount: (accountId: string) => dispatch(setActiveAccount(accountId)),
})

export default withRouter(connect(mapState, mapDispatch)(Navigation))
