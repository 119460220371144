import React from 'react'
import { ErrorMessage as FormikErrorMessage } from 'formik'
import styled from 'styled-components'
import { AlertType } from '../alerts/Alert'
import Small from '../typography/Small'

const ErrorComponent = styled(Small)`
  color: ${({ theme }) => theme.color.error};
`

interface ErrorMessageProps {
  alert?: boolean
  alertType?: AlertType
  name: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ alert, alertType, name }) => (
  <FormikErrorMessage
    name={name}
    component={ErrorComponent}
  />
)

export default ErrorMessage
