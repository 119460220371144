import React from 'react';
import styled from 'styled-components';

interface CheckboxProps {
  error?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export default styled.input<CheckboxProps>`
  display: block;
  padding: 1rem .75rem;
`;
