import React from 'react'
import { FieldProps } from 'formik'
import Textarea from '../inputs/Textarea'

interface TextareaFieldProps extends FieldProps {
  placeholder?: string
  rows?: number
}

const TextareaField: React.FC<TextareaFieldProps> = ({
  placeholder,
  field,
  rows,
  form: { errors, touched }
}) => (
  <Textarea
    name={field.name}
    onChange={field.onChange}
    placeholder={placeholder}
    rows={rows || 3}
    value={field.value}
    error={typeof touched[field.name] !== 'undefined' && typeof errors[field.name] !== 'undefined'}
  />
)

export default TextareaField
