import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPages, Page, putPage } from '../api/pageApi';
import { AxiosResponse } from 'axios';
import Grid from '../components/grid/Grid';
import { AppState } from '../redux/reducers';
import {
  getActiveAccountId,
  getActiveAccountName,
} from '../selectors/envSelectors';
import PageHeader from '../components/layout/PageHeader';
import { RouteComponentProps } from 'react-router-dom';
import Modal, { ModalSize } from '../components/modals/Modal';
import Button from '../components/buttons/Button';
import ButtonGroup from '../components/buttons/ButtonGroup';
import Alert, { AlertType } from '../components/alerts/Alert';

interface AccountPagesProps extends RouteComponentProps {
  accountId: string | null;
  accountName: string;
}

interface AccountPagesState {
  clearingCache: boolean;
  pages: Page[];
  loading: boolean;
  modalVisible: boolean;
  currentPage: string | null;
  modalError: string | null;
}

class AccountPages extends Component<AccountPagesProps, AccountPagesState> {
  state: AccountPagesState = {
    modalVisible: false,
    clearingCache: false,
    pages: [],
    loading: true,
    currentPage: null,
    modalError: null,
  };

  deletePage = () => {
    const pageId = this.state.currentPage;
    const page = this.state.pages.find((p) => p.id === pageId) || null;

    if (pageId && this.props.accountId) {
      putPage(this.props.accountId, pageId, {
        ...(page !== null && { ...page }),
        deleted_at: new Date(),
      })
        .then(() =>
          this.setState({ modalVisible: false }, () => {
            this.fetchPages();
          })
        )
        .catch((err) => {
          const message = 'Page was not deleted';

          this.setState({
            modalError: message,
          });
        });
    }
  };

  componentDidMount = () => {
    this.fetchPages();
  };

  componentDidUpdate = (prevProps: AccountPagesProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.setState({ loading: true }, () => this.fetchPages());
    }
  };

  fetchPages = () => {
    if (this.props.accountId) {
      getPages(this.props.accountId).then((res: AxiosResponse) => {
        this.setState({ pages: res.data.items, loading: false });
      });
    }
  };

  render = () => (
    <div>
      <PageHeader
        actions={[
          {
            key: 'create',
            label: 'New page',
            onClick: () =>
              this.props.history.push(
                `/${this.props.accountName}/pages/create`
              ),
          },
        ]}
      >
        Pages
      </PageHeader>
      {this.state.modalVisible && (
        <Modal
          title="Are you sure you want to delete this page?"
          hide={() => this.setState({ modalVisible: false })}
          size={ModalSize.SM}
        >
          {this.state.modalError && (
            <Alert type={AlertType.ERROR}>{this.state.modalError}</Alert>
          )}
          <ButtonGroup>
            <Button onClick={this.deletePage}>Yes</Button>
            <Button onClick={() => this.setState({ modalVisible: false })}>
              No
            </Button>
          </ButtonGroup>
        </Modal>
      )}
      <Grid
        isLoading={this.state.loading}
        actions={[
          {
            label: 'Edit',
            route: `/${this.props.accountName}/pages/:id`,
          },
          {
            label: 'Delete',
            onClick: ({ id }: any) => {
              this.setState({ modalVisible: true, currentPage: id });
            },
          },
        ]}
        columns={[
          {
            Header: 'Slug',
            accessor: 'slug',
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Type',
            accessor: 'type',
          },
          {
            Header: 'Page Id',
            accessor: 'id',
          },
        ]}
        data={this.state.pages}
      />
    </div>
  );
}

const mapState = (state: AppState) => ({
  accountName: getActiveAccountName(state),
  accountId: getActiveAccountId(state),
});

export default connect(mapState)(AccountPages);
