import { AxiosPromise } from 'axios';
import { get, post, put } from '.';
import { ImageSizes } from './fileApi';

export interface Product {
  id: string;
  _id: string;
  name: string;
  image: ImageSizes;
  from_price?: string;
}

export const getProducts = (accountId: string): AxiosPromise<Product[]> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/products`,
    params: { locale: 'nl' },
  });

export const getProduct = (
  accountId: string,
  productId: string
): AxiosPromise<Product> =>
  get({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/products/${productId}`,
  });

export const postProduct = (
  accountId: string,
  data: object
): AxiosPromise<Product> =>
  post({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/products`,
    data,
  });

export const putProduct = (
  accountId: string,
  productId: string,
  data: object
): AxiosPromise<Product> =>
  put({
    url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/products/${productId}`,
    data,
  });
