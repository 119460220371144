import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../redux/reducers'
import { getActiveAccountId } from '../selectors/envSelectors'
import PageHeader, { PageHeaderAction } from '../components/layout/PageHeader'
import Alert, { AlertType } from '../components/alerts/Alert'
import OfferForm from '../components/form/forms/OfferForm'
import { getOffer, Offer } from '../api/offerApi'
import FormSkeleton from '../components/layout/FormSkeleton'
import { clearCache } from '../api/cacheApi'

interface RouteParams {
  id: string
}

interface AccountOffersDetailProps extends RouteComponentProps<RouteParams> {
  accountId: string | null
}

interface AccountOffersDetailState {
  clearingCache: boolean
  offer?: Offer
  success: boolean
}

class AccountOffersDetail extends Component<AccountOffersDetailProps, AccountOffersDetailState> {
  state: AccountOffersDetailState = {
    clearingCache: false,
    success: false
  }

  getPageActions = (): PageHeaderAction[] => {
    return []
  }

  componentDidMount = () => {
    this.fetchOffer()
  }

  componentDidUpdate = (prevProps: AccountOffersDetailProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.fetchOffer();
    }
  }

  fetchOffer = () => {
    if (typeof this.props.match.params.id !== 'undefined' && this.props.accountId) {
      getOffer(this.props.accountId, this.props.match.params.id)
        .then((res) => {
          this.setState({ offer: res.data })
        })
    }
  }

  clearOfferCache = () => {
    if (this.props.accountId) {
      this.setState({ clearingCache: true })
      clearCache(this.props.accountId, `/offers/slug${this.state.offer?.slug}`)
        .then(() => this.setState({ clearingCache: false }))
    }
  }

  render = () => {
    if (typeof this.state.offer === 'undefined') {
      return <FormSkeleton />
    }

    return (
      <div>
        {this.state.success && (
          <Alert type={AlertType.SUCCESS}>
            Update successful
          </Alert>
        )}
        <PageHeader actions={this.getPageActions()}>
        {this.state.offer.name}
        </PageHeader>       
        <OfferForm
          offer={this.state.offer}
          onSaved={
            (offer) => {
              this.setState(
                { offer, success: true },
                () => setTimeout(() => this.setState({ success: false }), 2000)
              )
              this.clearOfferCache()
            }
          }
        />
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(AccountOffersDetail)
