import React from 'react'
import Gravatar from 'react-gravatar'
import styled from 'styled-components'
import { Auth } from '@aws-amplify/auth'
import { CognitoUserInterface } from '@aws-amplify/ui-components'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { Link } from 'react-router-dom'

const Container = styled.div`
  height: 3.2rem;
  display: flex;
  flex-direction: row;
  margin: 1.2rem 2rem 1.2rem 0;
`
const Image = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 3.2rem;
  overflow: hidden;
  margin-right: 1rem;
`

const Username = styled.div`
  line-height: 3.2rem;
  font-weight: bold;
  margin-right: 1rem;
`

const Profile: React.FC = () => {
  const [user, setUser] = React.useState<CognitoUserInterface | undefined>()

  React.useEffect(
    () => {
      Auth.currentAuthenticatedUser()
        .then(authenticatedUser => setUser(authenticatedUser))
    },
    []
  )

  return (
    <Container>
      {typeof user !== 'undefined' && (
        <>
          <Image>
            <Gravatar email={user.attributes.email} size={32} />
          </Image>
          <Link to="/profile">
            <Username>{user.attributes.email}</Username>
          </Link>
          <AmplifySignOut />
        </>
      )}
    </Container>
  )
}

export default Profile
