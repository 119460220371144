import styled from 'styled-components';

interface LabelProps {
  required?: boolean;
}

export default styled.label<LabelProps>`
  display: inline-block;
  margin-bottom: .5rem;
  :after {
    content: ${({ required }) => required ? '" *"' : ''}
  }
`;
