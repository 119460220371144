import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Page } from '../api/pageApi';
import { AppState } from '../redux/reducers'
import { getActiveAccountId, getActiveAccountName } from '../selectors/envSelectors'
import PageForm from '../components/form/forms/PageForm'
import PageHeader from '../components/layout/PageHeader'

interface RouteParams {
  id: string
}

interface AccountPagesCreateProps extends RouteComponentProps<RouteParams> {
  accountId: string | null
  accountName: string
}

interface AccountPagesCreateState {
  page?: Page
}

class AccountPagesCreate extends Component<AccountPagesCreateProps, AccountPagesCreateState> {
  state: AccountPagesCreateState = {}

  render = () => {
    const { accountName, history } = this.props
    return (
      <div>
        <PageHeader>Create new page</PageHeader>
        
        <PageForm
          onSaved={() => history.push(`/${accountName}/pages`)}
        />
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state),
  accountName: getActiveAccountName(state)
})

export default connect(mapState)(AccountPagesCreate)
