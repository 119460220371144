import React from 'react'
import styled from 'styled-components'
import AccountPicker from '../navigation/AccountPicker'
import Profile from './Profile'

const BarContainer = styled.div`
  position: fixed;
  top: .3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 5.6rem;
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor.default}; 
  z-index: 10;
`

const Brand = styled.div`
  width: 24rem;
  height: 5.6rem;
  padding: 0.8rem 2rem;
  border-right: 1px solid #DFE4EC;
`

const Search = styled.div`
  flex: 1;
`

const TopBar: React.FC = () => (
  <BarContainer>
    <Brand>
      <AccountPicker />
    </Brand>
    <Search />
    <Profile />
  </BarContainer>
)

export default TopBar
