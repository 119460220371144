import { AxiosPromise } from 'axios'
import { get, put, post } from '.'
import { ImageSizes } from './fileApi'

export interface Vendor {
  id: string
  active: boolean
  name: string
  label: string
  main_image: ImageSizes
  background_image: ImageSizes
  logo: string
  logo_secondary: string
  location: string
  description: string
  short_description: string
  gallery: ImageSizes[]
  contact_information: string
  slug: string

  indexable: boolean
  meta_description?: string
  meta_title?: string
}

export const getVendors = (accountId: string): AxiosPromise<Vendor[]> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/vendors`
})

export const getVendor = (accountId: string, vendorId: string): AxiosPromise<Vendor> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/vendors/${vendorId}`
})

export const postVendor = (accountId: string, data: object): AxiosPromise<Vendor> => post({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/vendors`,
  data
})

export const putVendor = (accountId: string, vendorId: string, data: object): AxiosPromise<Vendor> => put({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/vendors/${vendorId}`,
  data
})
