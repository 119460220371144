import React from 'react'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import { theme } from '../../../theme'

export interface SelectOption {
  value: string | number;
  label: string;
}

const customStyles = {
  control: (provided: object) => ({
    ...provided,
    boxShadow: 'transparent 0px 0px 0px 1px, rgba(22, 29, 37, 0.05) 0px 1px 0px 0px',
    borderColor: theme.borderColor.default,
  })
}

type Props = ReactSelectProps<SelectOption> & {
  creatable?: boolean;
};

const Select: React.FC<Props> = ({ creatable, ...props }) => {
  const SelectComponent = creatable ? CreatableSelect : ReactSelect;

  // @ts-ignore Require more robust types from react-select
  return <SelectComponent styles={customStyles} {...props} />;
};

export default Select
