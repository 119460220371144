import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Button from '../../buttons/Button';
import FormGroup from '../FormGroup';
import Label from '../Label';
import {
  CheckIcon,
  ExclamationCircleIcon,
  PencilIcon,
  XIcon,
} from '@heroicons/react/solid';
import BasketFormModal from './BasketFormModal';
import { v4 as uuidv4 } from 'uuid';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinesContainer = styled.div`
  margin: ${({ theme }) => theme.margin.md} 0;
`;

const EmpyStateLabel = styled.p`
  color: ${({ theme }) => theme.textColor.muted};
`;

const BasketInfoLinesContainer = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  padding: ${({ theme }) => theme.margin.md};
`;

const BasketInfoLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.color.lightGrey};
  padding: ${({ theme }) => theme.margin.sm};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.margin.sm};
  }
`;

const BasketInfoLineDescription = styled.div`
  text-overflow: ellipsis;
`;

const BasketInfoLineActions = styled.div`
  display: flex;
`;

const IconAndDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.color.success};
  width: 15px;
  height: 15px;
  margin-right: ${({ theme }) => theme.margin.sm};
`;

const StyledExclamationIcon = styled(ExclamationCircleIcon)`
  color: ${({ theme }) => theme.color.error};
  width: 15px;
  height: 15px;
  margin-right: ${({ theme }) => theme.margin.sm};
  flex-shrink: 0;
`;

const StyledPencilIcon = styled(PencilIcon)`
  color: ${({ theme }) => theme.textColor.default};
  width: 15px;
  height: 15px;
  margin-right: ${({ theme }) => theme.margin.sm};
  cursor: pointer;
  flex-shrink: 0;
`;

const StyledXIcon = styled(XIcon)`
  color: ${({ theme }) => theme.textColor.default};
  width: 15px;
  height: 15px;
  margin-right: ${({ theme }) => theme.margin.sm};
  cursor: pointer;
`;

export interface BasketInfoLine {
  id?: string;
  icon: { label: string; value: string };
  description: string;
}

interface BasketFormProps {
  basketInfoLines: BasketInfoLine[];
  onChange: (basketInfoLines: BasketInfoLine[]) => void;
}

const BasketForm: React.FC<BasketFormProps> = ({
  basketInfoLines,
  onChange,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [editBasketInfoLine, setEditBasketInfoLine] =
    useState<BasketInfoLine | null>(null);

  const updateOrAddBasketInfoLine = (basketInfoLine: BasketInfoLine) => {
    // basketInfoLine does not yet exist
    if (!basketInfoLine.id) {
      return addBasketInfoLine(basketInfoLine);
    }

    updateBasketInfoLine(basketInfoLine);
  };

  const updateBasketInfoLine = (basketInfoLine: BasketInfoLine) => {
    basketInfoLines[
      basketInfoLines.findIndex((b) => b.id === basketInfoLine.id)
    ] = basketInfoLine;
    onChange([...basketInfoLines]);
  };

  const addBasketInfoLine = (basketInfoLine: BasketInfoLine) => {
    basketInfoLine.id = uuidv4();
    onChange([...basketInfoLines, basketInfoLine]);
  };

  const deleteBasketinfoLine = (basketInfoLine: BasketInfoLine) => {
    const basketLines = basketInfoLines.filter(
      (b) => b.id !== basketInfoLine.id
    );
    onChange([...basketLines]);
  };

  return (
    <Fragment>
      <FormGroup>
        <Container>
          <Label>Basket pricing information</Label>

          <LinesContainer>
            {basketInfoLines.length === 0 && (
              <EmpyStateLabel>No basket info lines yet</EmpyStateLabel>
            )}
            {basketInfoLines.length > 0 && (
              <BasketInfoLinesContainer>
                {basketInfoLines.map((line, index) => (
                  <BasketInfoLineContainer key={index}>
                    <IconAndDescriptionContainer>
                      {line.icon?.value === 'check' && <StyledCheckIcon />}
                      {line.icon?.value === 'exclamation' && (
                        <StyledExclamationIcon />
                      )}
                      <BasketInfoLineDescription
                        dangerouslySetInnerHTML={{ __html: line.description }}
                      />
                    </IconAndDescriptionContainer>

                    <BasketInfoLineActions>
                      <StyledPencilIcon
                        onClick={() => {
                          setEditBasketInfoLine(line);
                          setModalVisible(true);
                        }}
                      />
                      <StyledXIcon onClick={() => deleteBasketinfoLine(line)} />
                    </BasketInfoLineActions>
                  </BasketInfoLineContainer>
                ))}
              </BasketInfoLinesContainer>
            )}
          </LinesContainer>

          <div>
            <Button type="button" onClick={() => setModalVisible(true)}>
              Add line
            </Button>
          </div>
        </Container>
      </FormGroup>
      {modalVisible && (
        <BasketFormModal
          onClose={() => {
            setModalVisible(false);
            setEditBasketInfoLine(null);
          }}
          onChange={updateOrAddBasketInfoLine}
          basketInfoLine={editBasketInfoLine}
        />
      )}
    </Fragment>
  );
};

export default BasketForm;
