import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../redux/reducers';
import { getActiveAccount } from '../selectors/envSelectors';
import { Account } from '../api/accountApi';
import { capitalize } from '../utils/stringUtils';
import PageHeader from '../components/layout/PageHeader';

interface DashboardProps {
  account: Account | null;
}

class Dashboard extends Component<DashboardProps, {}> {
  render = () => {
    const { account } = this.props;
    if (!account) {
      return null;
    }

    return (
      <div>
        <PageHeader>{capitalize(account.label)} Dashboard</PageHeader>
      </div>
    );
  }
}

const mapState = (state: AppState) => ({
  account: getActiveAccount(state),
});

export default connect(mapState)(Dashboard);
