import { Order } from '../api/orderApi';

export enum OrderStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  PAYMENT_FAILED = 'payment_failed',
  AWAITING_PAYMENT = 'awaiting_payment'
}

export const getOrderStatus = (order: Order): string => {
  const status = order.payment.status;
  const orderNumber = order.order_number;

  if ((order.payment.amount === 0 || status === 9) && orderNumber !== undefined) {
    return OrderStatus.SUCCESS
  }
  if (order.payment.amount > 0 && status === undefined) {
    return OrderStatus.AWAITING_PAYMENT
  }
  if (order.payment.amount > 0 && status !== 9) {
    return OrderStatus.PAYMENT_FAILED
  }
  return OrderStatus.FAILED
};
