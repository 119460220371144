import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getVendors, Vendor } from '../api/vendorApi'
import { AxiosResponse } from 'axios'
import Grid from '../components/grid/Grid'
import { AppState } from '../redux/reducers'
import { getActiveAccountId, getActiveAccountName } from '../selectors/envSelectors'
import PageHeader from '../components/layout/PageHeader'
import { RouteComponentProps } from 'react-router-dom'

interface AccountVendorsProps extends RouteComponentProps {
  accountId: string | null;
  accountName: string;
}

interface AccountVendorsState {
  clearingCache: boolean
  vendors: Vendor[];
  loading: boolean;
}

class AccountVendors extends Component<AccountVendorsProps, AccountVendorsState> {
  state = {
    clearingCache: false,
    vendors: [],
    loading: true,
  };

  componentDidMount = () => {
    this.fetchVendors()
  }

  componentDidUpdate = (prevProps: AccountVendorsProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.setState({ loading: true }, () => this.fetchVendors())
    }
  }

  fetchVendors = () => {
    if (this.props.accountId) {
      getVendors(this.props.accountId)
        .then((res: AxiosResponse) => {
          this.setState({ vendors: res.data.items, loading: false })
        })
    }
  }

  render = () => (
    <div>
      <PageHeader
        actions={[
          {
            key: 'create',
            label: 'New vendor',
            onClick: () => this.props.history.push(`/${this.props.accountName}/vendors/create`)
          }
        ]}
      >
        Vendors
      </PageHeader>
      <Grid
        isLoading={this.state.loading}
        actions={[
          { label: 'Edit', route: `/${this.props.accountName}/vendors/:id` }
        ]}
        columns={[
          {
            Header: 'Slug',
            accessor: 'slug'
          },
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Vendor Id',
            accessor: 'id'
          }
        ]}
        data={this.state.vendors}
      />
    </div>
  )
}

const mapState = (state: AppState) => ({
  accountName: getActiveAccountName(state),
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(AccountVendors)
