import React from 'react'
import { Formik, Form, Field } from 'formik'
import TextField from '../form/fields/TextField'
import styled from 'styled-components'
import Row from '../layout/Row'
import Col from '../layout/Col'
import SubmitButton from '../buttons/SubmitButton'

export interface Filter {
  name: string
}

interface FiltersProps {
  onChange: (values: {[key: string]: any}) => Promise<any>
}

const FilterForm = styled(Form)`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

const Filters: React.FC<FiltersProps> = ({ onChange }) => (
  <Formik
    initialValues={{ search: '' }}
    onSubmit={(values) => onChange(values)}
  >
    {({ isSubmitting }) => (
      <FilterForm>
        <Row>
          <Col col={12} md={4}>
            <Field name="search" component={TextField} placeholder="Search on first name, last name or emailaddress" />
          </Col>
          <Col col={12} md={3}>
            <SubmitButton isSubmitting={isSubmitting}>
              Search
            </SubmitButton>
          </Col>
        </Row>
      </FilterForm>
    )}
  </Formik>
)

export default Filters
