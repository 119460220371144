import React from 'react'
import H3 from '../../typography/H3'
import CheckboxField from '../fields/CheckboxField'
import FieldSet from '../fields/FieldSet'
import TextField from '../fields/TextField'

const SeoSubform = () => (
  <>
    <H3>Search Engine Optimization</H3>
    <FieldSet
      name="indexable"
      label="Indexable"
      helplabel="If checked, search engines will be allowed to index the page data"
      component={CheckboxField}
    />
    <FieldSet
      name="meta_title"
      label="Meta Title"
      helplabel={`
        A title displayed in the browsers' address bar and used for SEO purposes.
        If not defined, it will fall back to the objects' own title
      `}
      component={TextField}
    />
    <FieldSet
      name="meta_description"
      label="Meta Description"
      helplabel={`
        A short description of this object. Used to convince a user to navigate to this object. E.g. 
        Google will show this to inform the user about this page.
      `}
      component={TextField}
    />
  </>
)

export default SeoSubform
