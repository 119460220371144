import React from 'react';
import styled from 'styled-components';
import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';

export enum ModalSize {
  SM = '25%',
  MD = '50%',
  LG = '80%',
}

interface ModalProps {
  hide: () => void;
  title: string;
  size?: ModalSize;
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(32, 40, 51, 0.6);
`;

interface ModalWrapperProps {
  size: ModalSize;
}

const ModalWrapper = styled.div<ModalWrapperProps>`
  width: ${({ size }) => size};
  position: relative;
`;

const Modal: React.FC<ModalProps> = ({ children, hide, title, size = ModalSize.MD }) => (
  <Backdrop>
    <ModalWrapper size={size}>
      <ModalHeader hide={hide} title={title} />
      <ModalBody>
        {children}
      </ModalBody>
    </ModalWrapper>
  </Backdrop>
);

export default Modal;
