import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ShimmerRect from './ShimmerRect'
import FormGroup from '../form/FormGroup'
import { Row } from 'styled-bootstrap-grid'

const FormSkeleton = () => (
  <>
    <Row>
      <ShimmerRect width={350} height={36} margin="2rem" />
    </Row>
    <FormGroup>
      <ShimmerRect width={40} height={17} margin="0.5rem" />
      <ShimmerRect width={400} height={38} margin="0.5rem" />
    </FormGroup>
    <FormGroup>
      <ShimmerRect width={50} height={17} margin="0.5rem" />
      <ShimmerRect width={600} height={100} margin="0.5rem" />
    </FormGroup>
    <FormGroup>
      <ShimmerRect width={80} height={17} margin="0.5rem" />
      <ShimmerRect width={300} height={38} margin="0.5rem" />
    </FormGroup>
  </>
)

export default FormSkeleton
