import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../redux/reducers'
import { getActiveAccountName } from '../selectors/envSelectors'
import PageHeader from '../components/layout/PageHeader'
import { Offer } from '../api/offerApi'
import OfferForm from '../components/form/forms/OfferForm'

interface RouteParams {
  id: string
}

interface AccountOffersDetailProps extends RouteComponentProps<RouteParams> {
  accountId: string | null
  accountName: string
}

interface AccountOffersDetailState {
  offer?: Offer
  success: boolean
}

class AccountOffersCreate extends Component<AccountOffersDetailProps, AccountOffersDetailState> {
  state: AccountOffersDetailState = {
    success: false
  }

  render = () => {
    const { accountName, history } = this.props
    return (
      <div>
        <PageHeader>Create new offer</PageHeader>
        
        <OfferForm
          onSaved={() => history.push(`/${accountName}/offers`)}
        />
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountName: getActiveAccountName(state)
})

export default connect(mapState)(AccountOffersCreate)
