import React, { Component } from 'react';
import H1 from '../components/typography/H1';

class AdminDashboard extends Component<{}, {}> {
  render = () => {
    return (
      <div>
        <H1>Admin Dashboard</H1>
      </div>
    );
  }
}

export default AdminDashboard;
