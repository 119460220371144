import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../FormGroup';
import Label from '../Label';
import TextField from '../fields/TextField';
import { postPage, putPage, Page, PageType } from '../../../api/pageApi';
import { AppState } from '../../../redux/reducers';
import { getActiveAccountId } from '../../../selectors/envSelectors';
import MaskedField from '../fields/MaskedField';
import RichTextField from '../fields/RichTextField';
import SelectField from '../fields/SelectField';
import HelpLabel from '../HelpLabel';
import ErrorMessage from '../ErrorMessage';
import Hr from '../../typography/Hr';
import { getProducts, Product } from '../../../api/productApi';
import { AxiosResponse } from 'axios';
import FieldSet from '../fields/FieldSet';
import ImageUploadField from '../fields/ImageUploadField';
import OfferSelectField from '../fields/OfferSelectField';
import { Offer } from '../../../api/offerApi';
import SubmitButton from '../../buttons/SubmitButton';
import Row from '../../layout/Row';
import Col from '../../layout/Col';
import SeoSubform from '../subforms/SeoSubform';
import BasketForm, { BasketInfoLine } from '../subforms/BasketForm';

interface PageFormValues {
  background_image?: string;
  description: string;
  exposition_id?: string;
  locale: string;
  products?: string[];
  activities?: string[];
  offers?: string[];
  slug: string;
  title: string;
  name: string;
  type: PageType | '';
  indexable: boolean;
  meta_description: string;
  meta_title: string;
}

interface PageFormProps {
  accountId: string;
  onSaved: (page: Page) => void;
  page?: Page;
}

interface PageFormState {
  products: Product[];
  loading: boolean;
  basketInfoLines: BasketInfoLine[];
}

const pageSchema = Yup.object({
  description: Yup.string().required(),
  exposition_id: Yup.string().when('type', {
    is: 'rcx_exposition',
    then: Yup.string().required(),
  }),
  locale: Yup.string().required(),
  slug: Yup.string().required(),
  title: Yup.string().required(),
  name: Yup.string(),
  type: Yup.string().required(),
});

class PageForm extends React.Component<PageFormProps, PageFormState> {
  state = {
    products: [],
    loading: true,
    basketInfoLines: [],
  };

  componentDidMount = () => {
    const formatBasketLines = (line: any) => ({
      ...line,
      icon: { label: line.icon, value: line.icon },
    });
    this.fetchProducts();
    this.setState({
      basketInfoLines:
        this.props.page?.basketInfoLines.map(formatBasketLines) || [],
    });
  };

  componentDidUpdate = (prevProps: PageFormProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.setState({ loading: true }, () => this.fetchProducts());
    }
  };

  fetchProducts = () => {
    if (this.props.accountId) {
      getProducts(this.props.accountId).then((res: AxiosResponse) => {
        this.setState({ products: res.data.items, loading: false });
      });
    }
  };

  isEdit = () => typeof this.props.page !== 'undefined';

  render = () => {
    const { accountId, onSaved, page } = this.props;
    const emptyValue: string[] = [];

    return (
      <Formik
        initialValues={{
          background_image: page?.background_image?.thumbnail || '',
          description: page?.description || '',
          explanation: page?.explanation || '',
          exposition_id: page?.exposition_id || '',
          locale: page?.locale || 'nl',
          offers: (page?.offers || []).map((offer: Offer) => offer.id),
          products: (page?.products || []).map(
            (product: Product) => product._id
          ),
          activities: page?.activities || [],
          slug: page?.slug || '/',
          title: page?.title || '',
          name: page?.name || '',
          type: page?.type || '',
          checkout_warning_message: page?.checkout_warning_message || '',

          indexable: page?.indexable || true,
          meta_description: page?.meta_description || '',
          meta_title: page?.meta_title || '',
        }}
        onSubmit={(
          values: PageFormValues,
          formikHelpers: FormikHelpers<PageFormValues>
        ) => {
          const formatBasketInfoLine = (line: BasketInfoLine) => ({
            ...line,
            icon: line.icon.value,
          });
          const basketInfoLines =
            this.state.basketInfoLines.map(formatBasketInfoLine);

          if (typeof page !== 'undefined') {
            return putPage(accountId, page.id, {
              ...values,
              basketInfoLines,
            }).then((response) => {
              formikHelpers.setSubmitting(false);
              onSaved(response.data);
            });
          }

          return postPage(accountId, {
            ...values,
            basketInfoLines,
          }).then((response) => {
            formikHelpers.setSubmitting(false);
            onSaved(response.data);
          });
        }}
        validationSchema={pageSchema}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Type</Label>
                  <Field
                    name="type"
                    disabled={values.type !== ''}
                    component={SelectField}
                    options={[
                      // TODO: Filter these based on account
                      { label: 'Default', value: 'dierentuintickets_default' },
                      { label: 'Checkout', value: 'checkout' },
                      {
                        label: 'Cookie Declaration',
                        value: 'dierentuintickets_cookie_declaration',
                      },
                      { label: 'Homepage', value: 'dierentuintickets_home' },
                      { label: 'How To', value: 'dierentuintickets_how_to' },
                      {
                        label: 'Order Confirmation',
                        value: 'order_confirmation',
                      },
                      {
                        label: 'ReCreateX Exposition',
                        value: 'rcx_exposition',
                      },
                    ]}
                  />
                  <HelpLabel>
                    The type of the page defines what fields can be defined and
                    how the page is displayed. This cannot be changed later on.
                  </HelpLabel>
                  <ErrorMessage name="type" />
                </FormGroup>
                <FormGroup>
                  <Label>Language</Label>
                  <Field
                    name="locale"
                    component={SelectField}
                    options={[
                      { label: 'Dutch', value: 'nl' },
                      { label: 'English', value: 'en' },
                      { label: 'French', value: 'fr' },
                      { label: 'German', value: 'de' },
                    ]}
                  />
                  <HelpLabel>
                    {/* tslint:disable-next-line */}
                    The language of the page. This currently has no effect in
                    the app yet, but will change soon. So please configure
                    correctly
                  </HelpLabel>
                  <ErrorMessage name="locale" />
                </FormGroup>
                <FormGroup>
                  <Label>Title</Label>
                  <Field name="title" type="text" component={TextField} />
                  <HelpLabel>The page title.</HelpLabel>
                  <ErrorMessage name="title" />
                </FormGroup>
                <FormGroup>
                  <Label>Name</Label>
                  <Field name="name" type="text" component={TextField} />
                  <HelpLabel>The internal name of the page.</HelpLabel>
                  <ErrorMessage name="title" />
                </FormGroup>
                <FormGroup>
                  <Label>Description</Label>
                  <Field name="description" component={RichTextField} />
                  <HelpLabel>
                    The main page content. Can be used to describe users what
                    this page is used for.
                  </HelpLabel>
                  <ErrorMessage name="description" />
                </FormGroup>
                <FormGroup>
                  <Label>Slug</Label>
                  <Field
                    name="slug"
                    component={MaskedField}
                    options={{ lowercase: true }}
                  />
                  <HelpLabel>
                    {/* tslint:disable-next-line */}
                    The part after the domain where your page will be accessible
                    on. E.g. https://domain.tld/your/custom/slug. Must start
                    with a `/`.
                  </HelpLabel>
                  <ErrorMessage name="slug" />
                </FormGroup>
                <Hr />
                {/* TODO: All these custom fields must be rendered based on page definitions */}
                {[
                  'dierentuintickets_default',
                  'dierentuintickets_home',
                  'dierentuintickets_how_to',
                  'dierentuintickets_cookie_declaration',
                ].includes(values.type) && (
                  <FieldSet
                    name="background_image"
                    label="Background Image"
                    helplabel="The image that will be used as a background"
                    component={ImageUploadField}
                  />
                )}
                {(values.type === 'dierentuintickets_home' ||
                  values.type === 'dierentuintickets_how_to') && (
                  <FieldSet
                    name="explanation"
                    label="Explanation"
                    helplabel="A text that gives a textual explanation of the concept. Rendered alongside of the how-to-steps"
                    component={RichTextField}
                  />
                )}
                {values.type === 'dierentuintickets_home' && (
                  <FieldSet
                    name="offers"
                    label="Offers"
                    helplabel="Offers that will be highlighted on the home page"
                    component={OfferSelectField}
                    isMulti={true}
                  />
                )}
                {values.type === 'rcx_exposition' && (
                  <>
                    <FormGroup>
                      <Label>RCX exposition ID</Label>
                      <Field
                        name="exposition_id"
                        type="text"
                        component={TextField}
                      />
                      <HelpLabel>
                        The ReCreateX Exposition ID. Used to retrieve available
                        dates and products from ReCreateX.
                      </HelpLabel>
                      <ErrorMessage name="exposition_id" />
                    </FormGroup>
                    <FieldSet
                      name="activities"
                      label="Activities (RCX exposition IDs)"
                      helplabel="Combine multiple ReCreateX expositions into one."
                      component={SelectField}
                      isMulti={true}
                      creatable={true}
                      options={page?.activities?.map((id) => {
                        return { label: id, value: id };
                      })}
                      placeholder="Type the ID and press ENTER to add."
                      emptyValue={emptyValue}
                    />
                    {this.state.products.length > 0 && (
                      <FormGroup>
                        <Label>Additional products</Label>
                        <Field
                          name="products"
                          component={SelectField}
                          isMulti={true}
                          options={this.state.products.map(
                            (product: Product) => ({
                              label: product.name,
                              value: product._id,
                            })
                          )}
                          emptyValue={emptyValue}
                        />
                        <HelpLabel>
                          Additional products will be available for users
                          underneath the exposition products
                        </HelpLabel>
                        <ErrorMessage name="products" />
                      </FormGroup>
                    )}
                    <Hr />
                  </>
                )}
                {values.type === 'checkout' && (
                  <FormGroup>
                    <Label>Checkout warning message</Label>
                    <Field
                      name="checkout_warning_message"
                      component={RichTextField}
                    />
                    <HelpLabel>
                      The warning message that is shown at the bottom of the
                      checkout page
                    </HelpLabel>
                    <ErrorMessage name="checkout_warning_message" />
                  </FormGroup>
                )}
                <BasketForm
                  onChange={(basketInfoLines) =>
                    this.setState({ basketInfoLines })
                  }
                  basketInfoLines={this.state.basketInfoLines}
                />
                <Hr />
              </Col>
              <Col md={6}>
                <SeoSubform />
              </Col>
            </Row>

            <FormGroup>
              <SubmitButton isSubmitting={isSubmitting}>Save</SubmitButton>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  };
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state),
});

export default connect(mapState)(PageForm);
