import { createSelector } from 'reselect'
import { getAccounts } from './accountSelectors'
import { AppState } from '../redux/reducers'
import { Account } from '../api/accountApi'

export const getActiveAccountName = (state: AppState): string => state.env.account || ''

export const getActiveAccount = createSelector(
  getActiveAccountName,
  getAccounts,
  (name, accounts): Account | null => {
    if (!name) {
      return null
    }

    return accounts[name]
  }
)

export const getActiveAccountId = createSelector(
  getActiveAccount,
  (account): string => {
    if (!account) {
      return ''
    }

    return account.id
  }
)
