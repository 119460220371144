import { AxiosResponse } from 'axios'
import { getAccounts, Account } from '../api/accountApi'
import { normalize } from '../utils/normalizeUtils'
import { ThunkResult } from '../redux/types'
import { AccountState } from '../redux/accountsReducer'

export const SET_ACTIVE_ACCOUNT = 'SET_ACTIVE_ACCOUNT'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'

export interface SetAccountsAction {
  type: typeof SET_ACCOUNTS
  payload: AccountState
}

export const setAccounts = (accounts: AccountState): SetAccountsAction => ({
  type: SET_ACCOUNTS,
  payload: accounts
})

export interface SetActiveAccountAction {
  type: typeof SET_ACTIVE_ACCOUNT
  accountId: string
}

export const setActiveAccount = (id: string): SetActiveAccountAction => ({
  accountId: id,
  type: SET_ACTIVE_ACCOUNT,
})

export const fetchAccounts = (): ThunkResult<Promise<Account[]>> => (dispatch) => getAccounts()
  .then((response: AxiosResponse<Account[]>) => {
    const normalized = normalize<Account>(response.data, 'name')
    dispatch(setAccounts(normalized))

    return response.data
  })
