import { AxiosPromise } from 'axios'
import { post, remove } from '.'

export interface ImageSizes {
  [size: string]: string
}

interface FileUploadUrl {
  original_filename: string
  unique_filename: string
  url: string
}

export const createFile = (accountId: string, filename: string, filetype: string): AxiosPromise<FileUploadUrl> => post({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/files`,
  data: { filename, filetype }
})

export const deleteFile = (accountId: string, filename: string): AxiosPromise<{}> => remove({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/files/${filename}`
})
