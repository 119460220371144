import { SET_ACTIVE_ACCOUNT, SET_ACCOUNTS, SetAccountsAction, SetActiveAccountAction } from '../actions/accountActions'

type Actions = SetActiveAccountAction | SetAccountsAction

interface EnvState {
  account: string | null
  user: object | null
}

export const initialState: EnvState = {
  account: null,
  user: null,
}

export default (state = initialState, action: Actions): EnvState => {
  switch (action.type) {
    case SET_ACCOUNTS: {
      if (state.account === null) {
        return {
          ...state,
          account: Object.keys(action.payload)[0]
        }
      }
      return state
    }
    case SET_ACTIVE_ACCOUNT: {
      return {
        ...state,
        account: action.accountId,
      }
    }
    // case SET_USER: {
    //   return {
    //     ...state,
    //     user: action.user
    //   }
    // }
    default: {
      return state
    }
  }
}
