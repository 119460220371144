import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../redux/reducers'
import { getActiveAccountName } from '../selectors/envSelectors'
import PageHeader from '../components/layout/PageHeader'
import { Vendor } from '../api/vendorApi'
import VendorForm from '../components/form/forms/VendorForm'

interface RouteParams {
  id: string
}

interface AccountVendorsDetailProps extends RouteComponentProps<RouteParams> {
  accountId: string | null
  accountName: string
}

interface AccountVendorsDetailState {
  vendor?: Vendor
  success: boolean
}

class AccountVendorsCreate extends Component<AccountVendorsDetailProps, AccountVendorsDetailState> {
  state: AccountVendorsDetailState = {
    success: false
  }

  render = () => {
    const { accountName, history } = this.props
    return (
      <div>
        <PageHeader>Create new vendor</PageHeader>
        
        <VendorForm
          onSaved={() => history.push(`/${accountName}/vendors`)}
        />
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountName: getActiveAccountName(state)
})

export default connect(mapState)(AccountVendorsCreate)
