import React from 'react'
import { FieldProps } from 'formik'
import Checkbox from '../inputs/Checkbox'

interface CheckboxFieldProps extends FieldProps {
  type: string
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ field, type, form: { errors, touched } }) => (
  <Checkbox
    name={field.name}
    onChange={field.onChange}
    type={type || 'checkbox'}
    value={field.value}
    checked={field.value || false}
    error={typeof touched[field.name] !== 'undefined' && typeof errors[field.name] !== 'undefined'}
  />
)

export default CheckboxField
