import React from 'react'
import styled from 'styled-components'
import { useTable, Column } from 'react-table'
import { Link } from 'react-router-dom'

export interface Action {
  label: string
  route?: string
  isVisible?: (row: object) => boolean
  onClick?: (row: object) => void
}

interface TableProps {
  actions: Action[]
  columns: Column[]
  data: object[]
}

const actionFilter = (row: object) => (action: Action) => {
  if (action.isVisible) {
    return action.isVisible(row)
  }
  return true
}

const ActionLink = styled(Link)`
  margin-right: ${({ theme }) => theme.margin.sm};
`

const Table: React.FC<TableProps> = ({ actions, columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (// tslint:disable-next-line:jsx-key
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (// tslint:disable-next-line:jsx-key
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(
          (row, i) => {
            prepareRow(row)
            return (// tslint:disable-next-line:jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {// tslint:disable-next-line:jsx-key
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                {actions.length > 0 && (
                  <td>
                    {actions.filter(actionFilter(row.original)).map((action: Action) => (
                      <React.Fragment key={action.label}>
                        {action.route && (
                          // @ts-ignore
                          <ActionLink to={action.route.replace(':id', row.original.id)}>
                            {action.label}
                          </ActionLink>
                        )}
                        {action.onClick && (
                          <ActionLink
                            to="#"
                            onClick={() => {
                              if (action.onClick) {
                                action.onClick(row.original)
                              }
                            }}
                          >
                            {action.label}
                          </ActionLink>
                        )}
                      </React.Fragment>
                    ))}
                  </td>
                )}
              </tr>
            )
          }
        )}
      </tbody>
    </table>
  )
}

export default Table
