import React from 'react'
import { AmplifyTotpSetup } from '@aws-amplify/ui-react'
import { Auth } from '@aws-amplify/auth'
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components'
import Button from '../components/buttons/Button'
import Card from '../components/layout/Card'

const Profile: React.FC = () => {
  const [isConfiguringMfa, setConfiguringMfa] = React.useState(false)
  const [user, setUser] = React.useState<CognitoUserInterface | undefined>()
  React.useEffect(
    () => {
      Auth.currentAuthenticatedUser()
        .then(authenticatedUser => setUser(authenticatedUser))
    },
    []
  )

  return (
    <>
      <Card>
        {!isConfiguringMfa && (
          <Button onClick={() => setConfiguringMfa(true)}>
            Configure MFA
          </Button>
        )}
        {isConfiguringMfa && typeof user !== 'undefined' && (
          <AmplifyTotpSetup
            headerText="Scan the QR code with your MFA app"
            slot="totp-setup"
            user={user}
            handleAuthStateChange={(nextAuthState: AuthState, data?: object) => {
              setConfiguringMfa(false)
            }}
          />
        )}
      </Card>
    </>
  )
}

export default Profile
