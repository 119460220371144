import React from 'react';
import styled from 'styled-components';
import { FieldInputProps } from 'formik';
import 'react-quill/dist/quill.snow.css';

export interface FieldProps<V = any, FormValues = any> {
  field: FieldInputProps<V>;
}

const RichTextFieldContainer = styled.div`
  background: #fff;
`;

class RichTextField extends React.Component<FieldProps> {
  quill: any;

  constructor(props: FieldProps) {
    super(props);

    if (typeof window !== 'undefined') {
      // Quill does not support serverside rendering (since it depends on the `document`),
      // so we workaround it by only rendering it on the client side
      this.quill = require('react-quill');
    }
  }

  render = () => {
    const { field } = this.props;
    const Quill = this.quill;

    if (typeof window !== 'undefined' && Quill) {
      return (
        <RichTextFieldContainer>
          <Quill
            formats={[
              'header',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image',
              'align',
              // 'color'
            ]}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link', 'image'],
                [{ align: [] }],
                // [{ 'color': [] }],
                ['clean'],
              ],
            }}
            onChange={field.onChange(field.name)}
            theme="snow"
            value={field.value}
          />
        </RichTextFieldContainer>
      );
    } else {
      return <textarea value={field.value} />;
    }
  };
}

export default RichTextField;
