import React from 'react'
import styled from 'styled-components'
import H1 from '../typography/H1'
import ButtonGroup from '../buttons/ButtonGroup'
import Button from '../buttons/Button'

export interface PageHeaderAction {
  key: string
  label: string
  onClick: () => void
}

interface PageHeaderProps {
  actions?: PageHeaderAction[]
}

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

const PageTitle = styled(H1)`
  display: flex;
`

const PageActions = styled(ButtonGroup)`
  display: flex;
  margin-left: auto;

  ${Button} {
    margin-left: ${({ theme }) => theme.margin.sm};
    margin-right: 0;
  }
`

const PageHeader: React.FC<PageHeaderProps> = ({ actions, children }) => (
  <PageHeaderContainer>
    <PageTitle>{children}</PageTitle>
    {typeof actions !== 'undefined' && actions.length > 0 && (
      <PageActions>
        {actions.map((action: PageHeaderAction) => (
          <Button key={action.key} onClick={action.onClick}>
            {action.label}
          </Button>
        ))}
      </PageActions>
    )}
  </PageHeaderContainer>
)

export default PageHeader
