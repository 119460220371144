import { AxiosPromise } from 'axios'
import { get, put, post } from '.'
import { Vendor } from './vendorApi'
import { ImageSizes } from './fileApi'

export interface Offer {
  id: string
  vendor: Vendor
  active: boolean
  name: string
  valid_to: string
  slug: string
  conditions: string
  product_name: string
  product_description: string
  product_link: string
  product_validity: string
  product_from_price?: number
  product_price: number
  product_discount: string
  priority: number
  main_image: ImageSizes
  background_image: ImageSizes

  indexable: boolean
  meta_description: string
  meta_title: string
}

export const getOffers = (accountId: string): AxiosPromise<Offer[]> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/offers`
})

export const getOffer = (accountId: string, offerId: string): AxiosPromise<Offer> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/offers/${offerId}`
})

export const postOffer = (accountId: string, data: object): AxiosPromise<Offer> => post({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/offers`,
  data
})

export const putOffer = (accountId: string, offerId: string, data: object): AxiosPromise<Offer> => put({
  url: `${process.env.RAZZLE_API_URL}/accounts/${accountId}/offers/${offerId}`,
  data
})
