import { useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Button from '../../buttons/Button';
import Modal, { ModalSize } from '../../modals/Modal';
import RichTextField from '../fields/RichTextField';
import FormGroup from '../FormGroup';
import Select from '../inputs/Select';
import Label from '../Label';
import { BasketInfoLine } from './BasketForm';

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.color.error};
  margin-top: ${({ theme }) => theme.margin.md};
`;

interface BasketFormModalProps {
  onClose: () => void;
  onChange: (basketInfoLine: BasketInfoLine) => void;
  basketInfoLine: BasketInfoLine | null;
}

const BasketFormModal: React.FC<BasketFormModalProps> = ({
  onClose,
  onChange,
  basketInfoLine,
}) => {
  const validate = (values: { icon: any; description: string }) => {
    const errors: any = {};
    const requiredMessage = 'This field is required.';

    if (!values.icon) {
      errors.icon = requiredMessage;
    }

    if (!values.description) {
      errors.description = requiredMessage;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      icon: basketInfoLine?.icon || { label: '', value: '' },
      description: basketInfoLine?.description || '',
    },
    validate,
    onSubmit: (values) => {
      onClose();
      onChange({
        ...values,
        id: basketInfoLine?.id,
      });
    },
  });

  return (
    <Modal title="Add basket info line" hide={onClose} size={ModalSize.MD}>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <Label>Icon</Label>

          <Select
            name="icon"
            onChange={(value) => {
              console.log('on change ', value);
              formik.setFieldValue('icon', value);
            }}
            value={formik.values.icon}
            options={[
              { label: 'check', value: 'check' },
              { label: 'exclamation', value: 'exclamation' },
            ]}
          />

          {formik.errors.icon ? (
            <ErrorMessage>{formik.errors.icon}</ErrorMessage>
          ) : null}
        </FormGroup>

        <FormGroup>
          <Label>Description</Label>
          <RichTextField
            field={{
              name: 'description',
              value: formik.values.description,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
            }}
          />

          {formik.errors.description ? (
            <ErrorMessage>{formik.errors.description}</ErrorMessage>
          ) : null}
        </FormGroup>

        <Button type="submit">Submit</Button>
      </form>
    </Modal>
  );
};

export default BasketFormModal;
