import { Account } from '../api/accountApi'
import { SET_ACCOUNTS, SetAccountsAction } from '../actions/accountActions'

export interface AccountState {
  [key: string]: Account
}

export const initialState: AccountState = {}

export default (state = initialState, { type, payload }: SetAccountsAction) => {
  switch (type) {
    case SET_ACCOUNTS: {
      return {
        ...payload,
        ...state
      }
    }
    default: {
      return state
    }
  }
}
