import React from 'react'
import Select, { SelectOption } from '../inputs/Select'
import { FieldProps } from 'formik'

export interface SelectFieldProps extends FieldProps {
  disabled?: boolean;
  isMulti?: boolean;
  creatable?: boolean;
  options: SelectOption[];
  emptyValue?: string[] | null;
}

const getInitialValue = (options: SelectOption[], value: any, isMulti: boolean = false) => {
  if (!Array.isArray(options)) {
    return undefined;
  }

  if (isMulti && Array.isArray(value)) {
    return options.filter((option) => value.includes(option.value)) || undefined
  }

  return options.find((option) => option.value === value) || undefined
}

const SelectField: React.FC<SelectFieldProps> = (props) => {
  const { 
    disabled, field, form: { setFieldTouched, setFieldValue, errors, touched }, isMulti, emptyValue = null } = props

  return (
    <Select
      {...props}
      isDisabled={disabled}
      defaultValue={getInitialValue(props.options, field.value, isMulti)}
      field={field}
      onChange={(selected) => {
        if (!selected) {
          return setFieldValue(field.name, emptyValue);
        } else if (Array.isArray(selected)) {
          return setFieldValue(
            field.name,
            selected.map((option: SelectOption) => option.value)
          );
        }

        return setFieldValue(field.name, (selected as SelectOption).value);
      }}
      onBlur={() => setFieldTouched(field.name, true)}
    />
  );
}

export default SelectField
