import React from 'react'
import Input, { InputProps } from './Input'
import styled from 'styled-components'

interface PrependedInputProps extends InputProps {
  prepend: string
}

const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`

const InputGroupPrepend = styled.div`
  margin-right: -1px;
  display: flex;
`

const InputGroupText = styled.div`
  display: flex;
  align-items: center;
  padding: .375rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
`

const InputGroupInput = styled(Input)<InputProps>`
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
`

const PrependedInput: React.FC<PrependedInputProps> = ({ prepend, ...restProps }) => (
  <InputGroup>
    <InputGroupPrepend>
      <InputGroupText>{prepend}</InputGroupText>
    </InputGroupPrepend>
    <InputGroupInput {...restProps} />
  </InputGroup>
)

export default PrependedInput
