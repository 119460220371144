import React from 'react'
import styled from 'styled-components'
import { FieldProps } from 'formik'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const DateTimeFieldWrapper = styled.div`
  .react-datepicker {
    transform: scale(1.5) translate(35px, 25px);
  }
  .react-datepicker-wrapper {
    width: 100%;
    input {
      width: 100%;
      padding: 1rem .75rem;
    }
  }
`

const DateTimeField: React.FC<FieldProps> = ({ field, form }) => (
  <DateTimeFieldWrapper>
    <DatePicker
      selected={(field.value && new Date(field.value)) || null}
      onChange={date => form.setFieldValue(field.name, date)}
      timeInputLabel="Time:"
      dateFormat="dd-MM-yyyy HH:mm"
      showTimeInput
    />
  </DateTimeFieldWrapper>
)

export default DateTimeField
