import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import NavigationLabel from './NavigationLabel';

const NavLink = styled(Link)`
  display: flex;
  flex-direction: row;
  padding: .5rem;
  border-radius: ${({ theme }) => theme.borderRadius.default};

  :hover {
    text-decoration: none;
    background: #dde2e8;
  }
`

interface NavigationLinkProps {
  to: string;
  onClick?: () => void;
  icon?: any;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ children, to, onClick, icon }) => (
  <NavLink to={to} onClick={onClick}>
    {icon}
    <NavigationLabel>
      {children}
    </NavigationLabel>
  </NavLink>
);

export default NavigationLink;
