import React from 'react';
import { Provider } from 'react-redux';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After } from '@jaredpalmer/after';
import routes from './routes';
import configureStore from './redux/store';

const store = configureStore({});

ensureReady(routes).then(data => hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <After data={data} routes={routes} />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
));

if (module.hot) {
  module.hot.accept();
}
