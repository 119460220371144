import React from 'react';
import H1 from '../components/typography/H1';

const Expected: React.FC = () => (
  <>
    <H1>Not available yet</H1>
    <p>This page is currently unavailable, but will be in the near future</p>
  </>
);

export default Expected;
