import React from 'react';
import H1 from '../components/typography/H1';

const NotFound: React.FC = () => (
  <div>
    <H1>404 Not Found</H1>
    <p>This page is unavailable</p>
  </div>
);

export default NotFound;
