import React from 'react';
import { connect } from 'react-redux';
import Select, { SelectOption } from '../form/inputs/Select';
import { Account } from '../../api/accountApi';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from '../../redux/types';
import { fetchAccounts, setActiveAccount } from '../../actions/accountActions';

interface AccountPickerProps {
  accounts: { [key: string]: Account };
  activeAccountName: string | null;
  getAccounts: () => void;
  switchAccount: (accountId: string) => void;
}

class AccountPicker extends React.Component<AccountPickerProps, {}> {
  componentDidMount = () => {
    this.props.getAccounts();
  };

  switchAccount = (account: string) => {
    this.props.switchAccount(account);
  };

  render = () => {
    const { accounts, activeAccountName } = this.props;
    const activeAccount = accounts[activeAccountName || ''];

    return (
      <Select
        options={Object.values(accounts).map((account) => ({
          value: account.name,
          label: account.label,
        }))}
        value={{
          value: activeAccount?.name || '',
          label: activeAccount?.label || '',
        }}
        onChange={(value) => {
          const option = value as SelectOption;
          if (typeof option !== 'undefined' && option !== null) {
            this.switchAccount(`${option.value}`);
          }
        }}
      />
    );
  };
}

const mapState = (state: AppState) => ({
  accounts: state.accounts,
  activeAccountName: state.env.account,
});

const mapDispatch = (dispatch: ThunkDispatch) => ({
  getAccounts: () => dispatch(fetchAccounts()),
  switchAccount: (accountId: string) => dispatch(setActiveAccount(accountId)),
});

export default connect(mapState, mapDispatch)(AccountPicker);
