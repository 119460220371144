import React from 'react';
import styled from 'styled-components';

export interface TextareaProps {
  name: string
  value: string
  error?: boolean
  onChange?: (e: React.ChangeEvent<any>) => void
  rows?: number
}

export default styled.textarea<TextareaProps>`
  display: block;
  width: 100%;
  padding: 1rem .75rem;
  border: 1px solid #f9f9f9;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  :focus {
    border-color: ${({ theme }) => theme?.color?.primary};
  }
`
