import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../redux/reducers'
import { getActiveAccountId } from '../selectors/envSelectors'
import PageHeader, { PageHeaderAction } from '../components/layout/PageHeader'
import Alert, { AlertType } from '../components/alerts/Alert'
import VendorForm from '../components/form/forms/VendorForm'
import { getVendor, Vendor } from '../api/vendorApi'
import FormSkeleton from '../components/layout/FormSkeleton'
import { clearCache } from '../api/cacheApi'

interface RouteParams {
  id: string
}

interface AccountVendorsDetailProps extends RouteComponentProps<RouteParams> {
  accountId: string | null
}

interface AccountVendorsDetailState {
  clearingCache: boolean
  vendor?: Vendor
  success: boolean
}

class AccountVendorsDetail extends Component<AccountVendorsDetailProps, AccountVendorsDetailState> {
  state: AccountVendorsDetailState = {
    clearingCache: false,
    success: false
  }

  getPageActions = (): PageHeaderAction[] => {
    return []
  }

  componentDidMount = () => {
    this.fetchVendor()
  }

  componentDidUpdate = (prevProps: AccountVendorsDetailProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.fetchVendor();
    }
  }

  fetchVendor = () => {
    if (typeof this.props.match.params.id !== 'undefined' && this.props.accountId) {
      getVendor(this.props.accountId, this.props.match.params.id)
        .then((res) => {
          this.setState({ vendor: res.data })
        })
    }
  }

  clearVendorCache = () => {
    if (this.props.accountId) {
      this.setState({ clearingCache: true })
      clearCache(this.props.accountId, `/vendors/slug${this.state.vendor?.slug}`)
        .then(() => this.setState({ clearingCache: false }))
    }
  }

  render = () => {
    if (typeof this.state.vendor === 'undefined') {
      return <FormSkeleton />
    }

    return (
      <div>
        {this.state.success && (
          <Alert type={AlertType.SUCCESS}>
            Update successful
          </Alert>
        )}
        <PageHeader actions={this.getPageActions()}>
          {this.state.vendor.name}
        </PageHeader>       
        <VendorForm
          vendor={this.state.vendor}
          onSaved={
            (vendor) => {
              this.setState(
                { vendor, success: true },
                () => setTimeout(() => this.setState({ success: false }), 2000)
              )
              this.clearVendorCache()
            }
          }
        />
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(AccountVendorsDetail)
