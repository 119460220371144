import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'
import NotFound from './NotFound'
import Expected from './Expected'
import { ThunkDispatch } from '../redux/types'
import { setActiveAccount } from '../actions/accountActions'
import Accounts from './Accounts'
import AdminDashboard from './AdminDashboard'

class Admin extends Component<RouteComponentProps, {}> {
  render = () => {
    const { match } = this.props
    return (
      <Switch>
        <Route path={match.path} exact={true} component={AdminDashboard} />
        <Route path={`${match.path}/accounts`} exact={true} component={Accounts} />
        <Route path={`${match.path}/accounts/:id`} exact={true} component={Expected} />
        <Route component={NotFound} />
      </Switch>
    )
  }
}

const mapDispatch = (dispatch: ThunkDispatch) => ({
  switchAccount: (account: string) => dispatch(setActiveAccount(account)),
})

export default connect(null, mapDispatch)(Admin)
