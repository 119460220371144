import { Auth } from '@aws-amplify/auth'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

export const newCancellationToken = () => axios.CancelToken.source()
export const isCancellationError = (err: Error) => axios.isCancel(err)

const getHeaders = (config: AxiosRequestConfig) => ({
  'Content-Type': 'application/json',
  ...config.headers,
})

export const api = axios.create({
  // baseURL: process.env.RAZZLE_API_BASE_URL,
  // timeout: 5000
})

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login'
    }

    return Promise.reject(error)
  }
)

export const get = (config: AxiosRequestConfig) =>
  Auth.currentSession()
    .then((data) => api({
      method: 'GET',
      ...config,
      headers: {
        Authorization: `Bearer ${data.getIdToken().getJwtToken()}`,
        ...getHeaders(config)
      }
    }))

export const patch = (config: AxiosRequestConfig) => 
  Auth.currentSession()
    .then((data) => api({
      method: 'PATCH',
      ...config,
      headers: {
        Authorization: `Bearer ${data.getIdToken().getJwtToken()}`,
        ...getHeaders(config)
      }
    }))

export const put = (config: AxiosRequestConfig) =>
  Auth.currentSession()
    .then((data) => api({
      method: 'PUT',
      ...config,
      headers: {
        Authorization: `Bearer ${data.getIdToken().getJwtToken()}`,
        ...getHeaders(config)
      }
    }))

export const post = (config: AxiosRequestConfig) =>
  Auth.currentSession()
    .then((data) => api({
      method: 'POST',
      ...config,
      headers: {
        Authorization: `Bearer ${data.getIdToken().getJwtToken()}`,
        ...getHeaders(config)
      }
    }))

export const remove = (config: AxiosRequestConfig) =>
  Auth.currentSession()
    .then((data) => api({
      method: 'DELETE',
      ...config,
      headers: {
        Authorization: `Bearer ${data.getIdToken().getJwtToken()}`,
        ...getHeaders(config)
      }
    }))
