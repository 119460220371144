import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import RootProvider from '../components/providers/RootProvider'
import Dashboard from './Dashboard'
import Account from './Account'
import NotFound from './NotFound'
import Admin from './Admin'
import TopBar from '../components/layout/TopBar'
import Navigation from '../components/navigation/Navigation'
import Main from '../components/layout/Main'
import Profile from './Profile'

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  padding: 5.9rem 0 0 24rem;
`

const BrandBar = styled.div`
  position: fixed;
  width: 100%;
  height: 3px;
  background-image: linear-gradient(90deg, #103071 0%, #2A62E0 23%, #86E7CA 56%, #EFFF62 100%);
`;

class PrivateApp extends Component<{}, {}> {
  static async getInitialProps () {
    return {}
  }

  render = () => {
    if (typeof window === 'undefined') {
      return null
    }

    return (
      <RootProvider>
        <Container>
          <BrandBar />
          <TopBar />
          <Navigation />
          <Wrapper>
            <Main>
              <Switch>
                <Route path="/" exact component={Dashboard} />
                <Route path="/admin" component={Admin} />
                <Route path="/profile" component={Profile} />
                <Route path="/:account" component={Account} />
                <Route component={NotFound} />
              </Switch>
            </Main>
          </Wrapper>
        </Container>
      </RootProvider>
    )
  }
}

export default PrivateApp
