import React from 'react'
import styled from 'styled-components'
import Table, { Action } from './Table'
import { Column } from 'react-table'
import Pagination from './Pagination'
import Card from '../layout/Card'
import TableSkeleton from '../layout/TableSkeleton'
import Filters, { Filter } from './Filters'

const GridStyles = styled.div`
  table {
    width: 100%;
    margin: 0;
    line-height: 1.5;
    border-collapse: collapse;

    td {
      padding: 15px 15px;
      border-top: none;
      border: none;
    }

    thead {
      tr {
        padding-top: 9px;
        padding-bottom: 9px;
      }

      td,
      th {
        padding: 10px 15px;
        border: none;
        color: ${({ theme }) => theme.textColor.muted};
        text-align: left;
      }
    }

    tbody {
      tr {
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }
  }
`

export interface ChangeSet {
  filter: {[key: string]: any}
  page: number
}

interface GridProps {
  actions: Action[]
  columns: Column[]
  data: object[]
  filters?: Filter[]
  isLoading?: boolean
  limit?: number
  onChange?: (changeSet: ChangeSet) => Promise<any>
  page?: number
  showPagination?: boolean
  total?: number
}

interface GridState {
  filter: {[key: string]: any},
  page: number
}

class Grid extends React.Component<GridProps, GridState> {
  constructor(props: GridProps) {
    super(props)

    this.state = {
      filter: {},
      page: 0
    }
  }

  handleChange = (changeSet: ChangeSet): Promise<any> => {
    if (typeof this.props.onChange === 'undefined') {
      return Promise.resolve()
    }

    return this.props.onChange(changeSet)
  }

  render = () => {
    const {
      actions,
      columns,
      data,
      filters,
      isLoading,
      limit,
      onChange,
      showPagination,
      total
    } = this.props
    const {
      page,
      filter
    } = this.state

    return (
      <>
        {typeof filters !== 'undefined' && filters.length > 0 && (
          <Filters
            onChange={(newFilters) => {
              this.setState({ page: 0, filter: newFilters })
  
              return this.handleChange({ page: 0, filter: newFilters })
            }}
          />
        )}
        <Card>
          {isLoading === true && <TableSkeleton />}
          {(typeof isLoading === 'undefined' || isLoading === false) && ( 
            <GridStyles>
              <Table
                actions={actions}
                columns={columns}
                data={data}
              />
              {showPagination === true && (
                <Pagination
                  limit={limit || 25}
                  nextPage={(newPage) => {
                    this.setState({ page: newPage })
                    return this.handleChange({ page: newPage, filter })
                  }}
                  page={page || 0}
                  previousPage={(newPage) => {
                    this.setState({ page: newPage })
                    return this.handleChange({ page: newPage, filter })
                  }}
                  total={total || 0}
                />
              )}
            </GridStyles>
          )}
        </Card>
      </>
    )
  }
}

export default Grid
