import React from 'react';
import Button from '../buttons/Button';

interface PaginationProps {
  nextPage: (page: number) => void;
  page: number;
  previousPage: (page: number) => void;
  limit: number;
  total: number;
}

const Pagination: React.FC<PaginationProps> = ({ limit, nextPage, page, previousPage, total }) => {
  return (
    <div>
      {page > 0 && (
        <Button onClick={() => previousPage(page - 1)}>
          {'<'}
        </Button>
      )}
      {total > ((page + 1) * limit) && (
        <Button onClick={() => nextPage(page + 1)}>
          {'>'}
        </Button>
      )}
    </div>
  );
};

export default Pagination;
