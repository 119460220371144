import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FieldProps } from 'formik'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { createFile } from '../../../api/fileApi'
import { AppState } from '../../../redux/reducers'
import { getActiveAccountId } from '../../../selectors/envSelectors'

interface ImageUploadFieldProps extends FieldProps {
  accountId: string
  multiple?: boolean
}

const DropzoneArea = styled.div`
  border: 2px dashed ${({ theme }) => theme.borderColor.default};
  cursor: pointer;
  padding: ${({ theme }) => theme.padding.lg};
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const Uploads = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const UploadedImage = styled.div<{ isUploading: boolean }>`
  border: 1px solid ${({ theme }) => theme.borderColor.default};
  padding: ${({ theme }) => theme.padding.md};
  margin-right: ${({ theme }) => theme.padding.md};

  img {
    height: 100px;
    filter: ${({ isUploading }) => isUploading ? 'grayscale(1)' : 'none'};
  }
`

interface UploadedFile extends File {
  isUploaded?: boolean
  uniqueFilename?: string
  uploadFailed?: boolean
}

const getValueAsArray = (value: string | string[]): string[] => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return []
  }
  if (typeof value === 'string') {
    return [value]
  }

  return value
}

const getFileSrc = (fileUrl: string, acceptedFiles: UploadedFile[]): string => {
  if (fileUrl.startsWith('https')) {
    return fileUrl
  }

  const file = acceptedFiles.find((acceptedFile) => acceptedFile.uniqueFilename === fileUrl)
  if (typeof file === 'undefined') {
    return ''
  }

  return URL.createObjectURL(file)
}

const ImageUploadField: React.FC<ImageUploadFieldProps> = ({ accountId, multiple, field, form: { setFieldValue } }) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: 'image/*',
    multiple: multiple || false,
    onDrop: (accepted: UploadedFile[]) =>
      Promise.all(
        accepted.map(
          (file) => createFile(accountId, file.name, file.type)
            .then((res) => ({
              uniqueFilename: res.data.unique_filename,
              uploadUrl: res.data.url
            }))
            .then((res) => {
              file.uniqueFilename = res.uniqueFilename
              return axios.put(res.uploadUrl, file, {
                headers: { 'Content-Type': file.type, 'x-amz-acl': 'public-read' }
              }).then(() => {
                file.isUploaded = true
                return res.uniqueFilename
              })
            })
            .catch(() => {
              file.uploadFailed = true
            })
          ))
        .then((results) => {
          if (multiple === true) {
            return setFieldValue(field.name, field.value.concat(results))
          }
          
          setFieldValue(field.name, results[0])
        })
  })

  const uploadedImages = getValueAsArray(field.value)
  const uploadingImages = acceptedFiles.filter((file: UploadedFile) => file.isUploaded !== true)

  return (
    <>
      {(multiple === true || (uploadedImages.length < 1 && uploadingImages.length < 1)) && (
        <DropzoneArea {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drop files here, or click to select files from your local disk</p>
          )}
        </DropzoneArea>
      )}
      
      <Uploads>
        {uploadedImages.map((fileUrl: string) => (
          <UploadedImage key={fileUrl} isUploading={false}>
            <img src={getFileSrc(fileUrl, acceptedFiles)} />
            <div>
              <button
                onClick={() => {
                  if (multiple === true) {
                    const newValues = [...field.value].filter((item: string) => item !== fileUrl)
                    setFieldValue(field.name, newValues)
                  } else {
                    setFieldValue(field.name, '')
                  }
                }}
              >
                Delete
              </button>
            </div>
          </UploadedImage>
        ))}
        {uploadingImages.map((file: UploadedFile) => (
          <UploadedImage key={file.name} isUploading={true}>
            <img src={URL.createObjectURL(file)} />
            <div>
              {file.uploadFailed === true ? 'Upload failed' : 'Uploading...'}
            </div>
          </UploadedImage>
        ))}
      </Uploads>
    </>
  )
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(ImageUploadField)
