import React from 'react'
import { AmplifyAuthenticator, AmplifyConfirmSignIn, AmplifySignIn } from '@aws-amplify/ui-react'
import { ThemeProvider } from 'styled-components'
import { BaseCSS } from 'styled-bootstrap-grid'
import { theme as layoutTheme } from '../../theme'
import GlobalStyle from '../layout/GlobalStyle'
import '../../amplify'
import { AuthState, CognitoUserInterface, onAuthUIStateChange } from '@aws-amplify/ui-components'

const RootProvider: React.FC<{}> = ({ children }) => {
  const [authState, setAuthState] = React.useState<AuthState>()
  const [user, setUser] = React.useState<CognitoUserInterface | undefined>()

  React.useEffect(
    () => {
      return onAuthUIStateChange((nextAuthState, authData) => {
          setAuthState(nextAuthState);
          setUser(authData as CognitoUserInterface)
      })
    },
    []
  )

  return (
    <ThemeProvider theme={layoutTheme}>
      <>
        <BaseCSS />
        <GlobalStyle />
        {authState === AuthState.SignedIn && user ? children : (
          <AmplifyAuthenticator>
            <AmplifySignIn headerText="Log in" slot="sign-in" hideSignUp={true} />
            <AmplifyConfirmSignIn headerText="Your MFA code" slot="confirm-sign-in" user={user} />
          </AmplifyAuthenticator>
        )}
      </>
    </ThemeProvider>
  )
}

export default RootProvider
