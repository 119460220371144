import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    font-size: 62.5%;
    color: ${({ theme }) => theme.textColor.default};
    margin: 0;
    padding: 0;
    background-color: #fff;
  }

  html, body, textarea, input, select {
    font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  }

  body {
    font-size: 1.4rem;
    background: linear-gradient(152.32deg, #FAFAFA 0%, #DFE4EC 100%);
  }

  :root {
    --amplify-text-xxs: 0.8rem;
    --amplify-text-xs: 1rem;
    --amplify-text-sm: 1.2rem;
    --amplify-text-md: 1.4rem;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    /* font-size: 100%;
    font: inherit; */
    vertical-align: baseline;
  }

  /* make sure to set some focus styles for accessibility */
  :focus {
    outline: 0;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  a {
    text-decoration: none;
  }
  a, a:hover, a:visited {
    color: ${({ theme }) => theme.textColor.default};
  }
  a:hover {
    text-decoration: underline;
  }

  button {
    background-color: inherit;
    border: none;
  }

  button,
  input,
  select,
  textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
  }

  button,
  input {
    line-height: normal;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"], /* 1 */
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  @keyframes shimmerFrames {
    0%,
    to {
      transform: translateZ(0);
    }
    0% {
      background-position: -468px 0;
    }
    to {
      background-position: 468px 0;
    }
  }

  amplify-sign-in,
  amplify-confirm-sign-in {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;
  }
`;

export default GlobalStyle;
