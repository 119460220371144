import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../FormGroup';
import {
  getProducts,
  postProduct,
  putProduct,
  Product,
} from '../../../api/productApi';
import { AppState } from '../../../redux/reducers';
import { getActiveAccountId } from '../../../selectors/envSelectors';
import { AxiosResponse } from 'axios';
import FieldSet from '../fields/FieldSet';
import ImageUploadField from '../fields/ImageUploadField';
import SubmitButton from '../../buttons/SubmitButton';
import Row from '../../layout/Row';
import Col from '../../layout/Col';
import HelpLabel from '../HelpLabel';
import ErrorMessage from '../ErrorMessage';
import Label from '../Label';
import TextField from '../fields/TextField';

interface ProductFormValues {
  image: string;
  from_price: string | null;
}

interface ProductFormProps {
  accountId: string;
  onSaved: (product: Product) => void;
  product?: Product;
}

interface ProductFormState {
  products: Product[];
  loading: boolean;
}

const productSchema = Yup.object({});

class ProductForm extends React.Component<ProductFormProps, ProductFormState> {
  state = {
    products: [],
    loading: true,
  };

  componentDidMount = () => {
    this.fetchProducts();
  };

  componentDidUpdate = (prevProps: ProductFormProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.setState({ loading: true }, () => this.fetchProducts());
    }
  };

  fetchProducts = () => {
    if (this.props.accountId) {
      getProducts(this.props.accountId).then((res: AxiosResponse) => {
        this.setState({ products: res.data.items, loading: false });
      });
    }
  };

  isEdit = () => typeof this.props.product !== 'undefined';

  render = () => {
    const { accountId, onSaved, product } = this.props;
    const emptyValue: string[] = [];

    return (
      <Formik
        initialValues={{
          image: product?.image?.thumbnail || '',
          from_price: product?.from_price || null,
        }}
        onSubmit={(
          values: ProductFormValues,
          formikHelpers: FormikHelpers<ProductFormValues>
        ) => {
          if (typeof product !== 'undefined') {
            return putProduct(accountId, product.id, {
              ...values,
              // if image is in format https://d2hauskxyqb8w1.cloudfront.net, it was already uploaded
              // so we need to get the original image name once again
              ...(values.image.includes('https') && {
                image: values.image.split('/').pop(),
              }),
              ...(values.from_price && {
                from_price: parseFloat(values.from_price),
              }),
            }).then((response) => {
              formikHelpers.setSubmitting(false);
              onSaved(response.data);
            });
          }

          return postProduct(accountId, { ...values }).then((response) => {
            formikHelpers.setSubmitting(false);
            onSaved(response.data);
          });
        }}
        validationSchema={productSchema}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Row>
              <Col md={6}>
                <FieldSet
                  name="image"
                  label="Image"
                  helplabel="The image that will be used as a product image"
                  component={ImageUploadField}
                />
                <FormGroup>
                  <Label>From price</Label>
                  <Field name="from_price" type="text" component={TextField} />
                  <HelpLabel>
                    The price before the discount (when using decimals, please
                    use a '.' delimiter, so format it as 4.5).
                  </HelpLabel>
                  <ErrorMessage name="title" />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <SubmitButton isSubmitting={isSubmitting}>Save</SubmitButton>
            </FormGroup>
          </Form>
        )}
      </Formik>
    );
  };
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state),
});

export default connect(mapState)(ProductForm);
