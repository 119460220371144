import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'
import Dashboard from './Dashboard'
import NotFound from './NotFound'
import AccountOrders from './AccountOrders'
import AccountProducts from './AccountProducts'
import { ThunkDispatch } from '../redux/types'
import { setActiveAccount } from '../actions/accountActions'
import AccountProductDetail from './AccountProductsDetail'
import AccountPages from './AccountPages'
import AccountPagesDetail from './AccountPagesDetail'
import AccountPagesCreate from './AccountPagesCreate'
import AccountVendorsDetail from './AccountVendorsDetail'
import AccountVendors from './AccountVendors'
import AccountVendorsCreate from './AccountVendorsCreate'
import AccountOffers from './AccountOffers'
import AccountOffersCreate from './AccountOffersCreate'
import AccountOffersDetail from './AccountOffersDetail'
import AccountOrdersDetail from './AccountOrdersDetail'

interface RouteParams {
  account: string;
}

interface AccountProps extends RouteComponentProps<RouteParams> {
  switchAccount: (account: string) => void;
}

class Account extends Component<AccountProps, {}> {
  componentDidMount = () => {
    const { match, switchAccount } = this.props
    switchAccount(match.params.account)
  }

  render = () => {
    const { match } = this.props
    return (
      <>
        <Switch>
          <Route path={match.path} exact component={Dashboard} />
          <Route path={`${match.path}/orders`} exact component={AccountOrders} />
          <Route path={`${match.path}/orders/:id`} exact component={AccountOrdersDetail} />
          <Route path={`${match.path}/offers`} exact component={AccountOffers} />
          <Route path={`${match.path}/offers/create`} exact component={AccountOffersCreate} />
          <Route path={`${match.path}/offers/:id`} exact component={AccountOffersDetail} />
          <Route path={`${match.path}/pages`} exact component={AccountPages} />
          <Route path={`${match.path}/pages/create`} exact component={AccountPagesCreate} />
          <Route path={`${match.path}/pages/:id`} exact component={AccountPagesDetail} />
          <Route path={`${match.path}/products`} exact component={AccountProducts} />
          <Route path={`${match.path}/products/:id`} exact component={AccountProductDetail} />
          <Route path={`${match.path}/vendors/create`} exact component={AccountVendorsCreate} />
          <Route path={`${match.path}/vendors/:id`} exact component={AccountVendorsDetail} />
          <Route path={`${match.path}/vendors`} exact component={AccountVendors} />
          <Route component={NotFound} />
        </Switch>
      </>
    )
  }
}

const mapDispatch = (dispatch: ThunkDispatch) => ({
  switchAccount: (account: string) => dispatch(setActiveAccount(account))
})

export default connect(null, mapDispatch)(Account)
