import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Page, getPage } from '../api/pageApi';
import { AppState } from '../redux/reducers'
import { getActiveAccountId } from '../selectors/envSelectors'
import PageForm from '../components/form/forms/PageForm';
import PageHeader, { PageHeaderAction } from '../components/layout/PageHeader';
import Alert, { AlertType } from '../components/alerts/Alert';
import { clearCache } from '../api/cacheApi';
import FormSkeleton from '../components/layout/FormSkeleton';

interface RouteParams {
  id: string;
}

interface AccountPagesDetailProps extends RouteComponentProps<RouteParams> {
  accountId: string | null;
}

interface AccountPagesDetailState {
  clearingCache: boolean
  page?: Page
  success: boolean
}

class AccountPagesDetail extends Component<AccountPagesDetailProps, AccountPagesDetailState> {
  state: AccountPagesDetailState = {
    clearingCache: false,
    success: false
  }

  componentDidMount = () => {
    this.fetchPage()
  }

  componentDidUpdate = (prevProps: AccountPagesDetailProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.fetchPage();
    }
  }

  fetchPage = () => {
    if (typeof this.props.match.params.id !== 'undefined' && this.props.accountId) {
      getPage(this.props.accountId, this.props.match.params.id)
        .then((res) => {
          this.setState({ page: res.data })
        })
    }
  }

  clearPageCache = () => {
    if (this.props.accountId) {
      this.setState({ clearingCache: true })
      clearCache(this.props.accountId, `/pages/slug${this.state.page?.slug}`)
        .then(() => this.setState({ clearingCache: false }))
    }
  }

  getPageActions = (): PageHeaderAction[] => {
    if (typeof this.props.match.params.id !== 'undefined' && this.props.accountId) {
      return [{
        key: 'clear-cache',
        label: this.state.clearingCache ? 'Clearing cache' : 'Clear cache',
        onClick: () => this.clearPageCache()
      }]
    }
    return []
  }

  render = () => {
    if (typeof this.state.page === 'undefined') {
      return <FormSkeleton />
    }

    return (
      <div>
        {this.state.success && (
          <Alert type={AlertType.SUCCESS}>
            Update successful
          </Alert>
        )}
        <PageHeader
          actions={this.getPageActions()}
        >
          {this.state.page.title}
        </PageHeader>
        <PageForm
          page={this.state.page}
          onSaved={
            (page) => {
              this.setState(
                { page, success: true },
                () => setTimeout(() => this.setState({ success: false }), 2000)
              )

              this.clearPageCache()
            }
          }
        />
        {this.state.success && (
          <Alert type={AlertType.SUCCESS}>
            Update successful
          </Alert>
        )}
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(AccountPagesDetail)
