import styled from 'styled-components';
import { darken } from 'polished';

export default styled.button`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.color.primary};
  padding: ${({ theme }) => theme.padding.md};
  color: #fff;
  font-weight: bold;
  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.color.primary)};
  }
`;
