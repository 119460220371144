import React from 'react'
import { FieldProps } from 'formik'
import Input from '../inputs/Input'
import PrependedInput from '../inputs/PrependedInput'

interface TextFieldProps extends FieldProps {
  placeholder?: string
  prepend?: string
  step?: string
  type: string
}

const TextField: React.FC<TextFieldProps> = ({
  placeholder,
  prepend,
  field,
  step,
  type,
  form: { errors, touched }
}) => {
  if (typeof prepend !== 'undefined') {
    return (
      <PrependedInput
        prepend={prepend}
        name={field.name}
        onChange={field.onChange}
        step={step}
        type={type || 'text'}
        value={field.value}
        error={typeof touched[field.name] !== 'undefined' && typeof errors[field.name] !== 'undefined'}
      />
    )
  }

  return (
    <Input
      name={field.name}
      onChange={field.onChange}
      placeholder={placeholder}
      step={step}
      type={type || 'text'}
      value={field.value}
      error={typeof touched[field.name] !== 'undefined' && typeof errors[field.name] !== 'undefined'}
    />
  )
}

export default TextField
