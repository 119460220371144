import React from 'react';
import { Props as CleaveProps } from 'cleave.js/react/props'
import MaskedInput from '../inputs/MaskedInput';
import { FieldProps } from 'formik';

type MaskedFieldProps = CleaveProps & FieldProps

const MaskedField: React.FC<MaskedFieldProps> = ({ field, options }) => (
  <MaskedInput
    name={field.name}
    onChange={field.onChange}
    value={field.value}
    options={options}
    // error={typeof touched[field.name] !== 'undefined' && typeof errors[field.name] !== 'undefined'}
  />
);

export default MaskedField;
