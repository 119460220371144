import styled from 'styled-components';

export enum BadgeType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface BadgeProps {
  type: BadgeType;
}

export default styled.span<BadgeProps>`
  background-color: ${({ theme, type }) => theme.color[type]};
  border-radius: 0.25rem;
  color: #fff;
  display: inline-block;
  font-size: 75%;
  line-height: 1;
  padding: ${({ theme }) => `${theme.padding.sm} ${theme.padding.sm}`};
`;
