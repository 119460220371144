import React from 'react'
import { Field, ErrorMessage, FieldAttributes } from 'formik'
import FormGroup from '../FormGroup'
import Label from '../Label'
import HelpLabel from '../HelpLabel'

interface FieldSetProps extends FieldAttributes<any> {
  prepend?: string
  label: string
  name: string
  helplabel: string
}

const FieldSet: React.FC<FieldSetProps> = ({ label, name, helplabel, ...restProps }) => (
  <FormGroup>
    <Label>{label}</Label>
    <Field name={name} {...restProps} />
    <HelpLabel>{helplabel}</HelpLabel>
    <ErrorMessage name={name} />
  </FormGroup>
)

export default FieldSet
