import { combineReducers } from 'redux';
import accounts from './accountsReducer';
import env from './envReducer';

const reducer = combineReducers({
  accounts,
  env
});

export type AppState = ReturnType<typeof reducer>;

export default reducer;
