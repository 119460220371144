import React from 'react'
import Button from './Button'
import Loader from '../loaders/Loader'

const SubmitButton: React.FC<{ isSubmitting: boolean }> = ({ isSubmitting, children }) => (
  <Button type="submit" disabled={isSubmitting}>
    {isSubmitting && (
      <Loader />
    )}
    {children}
  </Button>
)

export default SubmitButton
