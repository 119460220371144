import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, FormikHelpers } from 'formik'
import FormGroup from '../FormGroup'
import { AppState } from '../../../redux/reducers'
import { getActiveAccountId } from '../../../selectors/envSelectors'
import Hr from '../../typography/Hr'
import ImageUploadField from '../fields/ImageUploadField'
import { postOffer, putOffer, Offer } from '../../../api/offerApi'
import TextField from '../fields/TextField'
import CheckboxField from '../fields/CheckboxField'
import FieldSet from '../fields/FieldSet'
import DateTimeField from '../fields/DateTimeField'
import VendorSelectField from '../fields/VendorSelectField'
import RichTextField from '../fields/RichTextField'
import SubmitButton from '../../buttons/SubmitButton'
import Row from '../../layout/Row'
import Col from '../../layout/Col'
import SeoSubform from '../subforms/SeoSubform'

interface OfferFormValues {
  vendor: string
  active: boolean
  name: string
  valid_to: Date
  slug: string
  conditions: string
  product_name: string
  product_description: string
  product_link: string
  product_validity: string
  product_from_price: number|null
  product_price: number|null
  product_discount: string
  priority: number
  main_image: string
  background_image: string
  
  indexable: boolean
  meta_description: string
  meta_title: string
}

interface OfferFormErrors {
  vendor?: string
  active?: string
  name?: string
  indexable?: string
  valid_to?: string
  slug?: string
  conditions?: string
  product_name?: string
  product_description?: string
  product_link?: string
  product_validity?: string
  product_from_price?: string
  product_price?: string
  product_discount?: string
  priority?: string
  main_image?: string
  background_image?: string
  meta_description?: string
}

interface OfferFormProps {
  accountId: string
  onSaved: (offer: Offer) => void
  offer?: Offer
}

interface OfferFormState {
  loading: boolean;
}

class OfferForm extends React.Component<OfferFormProps, OfferFormState> {
  state = {
    loading: true,
  }

  isEdit = () => typeof this.props.offer !== 'undefined'

  render = () => {
    const { accountId, onSaved, offer } = this.props
    return (
      <Formik
        initialValues={{
          name: typeof offer !== 'undefined' ? offer.name : '',
          active: typeof offer !== 'undefined' ? offer.active : true,
          slug: typeof offer !== 'undefined' ? offer.slug : '',
          vendor: typeof offer !== 'undefined' ? offer.vendor?.id : '',
          valid_to: typeof offer !== 'undefined' ? new Date(offer.valid_to) : new Date(),
          conditions: typeof offer !== 'undefined' ? offer.conditions : '',
          product_name: typeof offer !== 'undefined' ? offer.product_name : '',
          product_description: typeof offer !== 'undefined' ? offer.product_description : '',
          product_link: typeof offer !== 'undefined' ? offer.product_link : '',
          product_validity: typeof offer !== 'undefined' ? offer.product_validity : '',
          product_from_price: (typeof offer !== 'undefined' && 
            typeof offer.product_from_price !== 'undefined') ? offer.product_from_price : null,
          product_price: typeof offer !== 'undefined' ? offer.product_price : null,
          product_discount: typeof offer !== 'undefined' ? offer.product_discount : '',
          priority: typeof offer !== 'undefined' ? offer.priority : 0,
          main_image: offer?.main_image?.thumbnail || '',
          background_image: offer?.background_image?.thumbnail || '',

          indexable: offer?.indexable || true,
          meta_description: offer?.meta_description || '',
          meta_title: offer?.meta_title || '',
        }}
        onSubmit={(values: OfferFormValues, formikHelpers: FormikHelpers<OfferFormValues>) => {
          if (typeof offer !== 'undefined') {
            return putOffer(accountId, offer.id, values)
              .then((response) => {
                formikHelpers.setSubmitting(false)
                onSaved(response.data)
              })
          }
          
          return postOffer(accountId, values)
            .then((response) => {
              formikHelpers.setSubmitting(false)
              onSaved(response.data)
            })
        }}
        validate={(values: OfferFormValues) => {
          const errors: OfferFormErrors = {}
          // TODO: Replace with YUP validators
          if (!values.name) {
            errors.name = 'This field is required'
          }
          return errors
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row>
              <Col md={6}>
                <FieldSet
                  name="name"
                  label="Title"
                  helplabel="This is the title of this page and is also used as display name for this offer."
                  component={TextField}
                />
                <FieldSet
                  prepend={typeof offer !== 'undefined' ? offer.vendor?.slug : '/:vendor'}
                  name="slug"
                  label="Slug"
                  helplabel="The slug to the offer page. This will be automatically prefixed with the vendor name. E.g. /:vendor/:offer"
                  component={TextField}
                />
                <FieldSet
                  name="active"
                  label="Active"
                  helplabel="Inactive offers will not be shown on any public pages."
                  component={CheckboxField}
                />
                <FieldSet
                  name="vendor"
                  label="Vendor"
                  helplabel="The vendor this offer belongs to"
                  component={VendorSelectField}
                  options=""
                />
                <FieldSet
                  name="valid_to"
                  label="Valid to"
                  helplabel="The date when the offer will expire (e.g. 2025-01-01 13:00:00)."
                  component={DateTimeField}
                />
                <FieldSet
                  name="conditions"
                  label="Conditions"
                  helplabel="The conditions of the offer."
                  component={RichTextField}
                />
                <FieldSet
                  name="product_name"
                  label="Product name"
                  helplabel="The name of the product this offer is for."
                  component={TextField}
                />
                <FieldSet
                  name="product_description"
                  label="Product description"
                  helplabel="The description is shown under the product name."
                  component={TextField}
                />
                <FieldSet
                  name="product_link"
                  label="Product link"
                  helplabel="The link to the ticketshop."
                  component={TextField}
                />
                <FieldSet
                  name="product_validity"
                  label="Product validity"
                  helplabel="The validity (e.g. until 10 April)."
                  component={TextField}
                />
                <FieldSet
                  name="product_from_price"
                  label="Product from price"
                  helplabel=""
                  type="number"
                  prepend="€"
                  step="0.01"
                  component={TextField}
                />
                <FieldSet
                  name="product_price"
                  label="Product price"
                  helplabel=""
                  type="number"
                  prepend="€"
                  step="0.01"
                  component={TextField}
                />
                <FieldSet
                  name="product_discount"
                  label="Product discount"
                  helplabel="A custom discount label, shown on the product. E.g. '30% korting'"
                  component={TextField}
                />
                <FieldSet
                  name="main_image"
                  label="Image"
                  helplabel="This is the image that is shown for this offer"
                  component={ImageUploadField}
                />
                <FieldSet
                  name="background_image"
                  label="Background image"
                  helplabel="This is the image that is shown on the background of this offer"
                  component={ImageUploadField}
                />
                <FieldSet
                  name="priority"
                  label="Priority"
                  helplabel="The priority is used to sort offers. Offers with a higher priority come before offers with a lower priority"
                  type="number"
                  component={TextField}
                />
              </Col>
              <Col md={6}>
                <SeoSubform />
              </Col>
            </Row>
            
            <Hr />
            <FormGroup>
              <SubmitButton isSubmitting={isSubmitting}>
                Save
              </SubmitButton>
            </FormGroup>
          </Form>
        )}
      </Formik>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(OfferForm)
