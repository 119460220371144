import styled from 'styled-components';
import { lighten } from 'polished';

export enum AlertType {
  ERROR = 'error',
  SUCCESS = 'success'
}

interface AlertProps {
  type: AlertType;
}

export default styled.div<AlertProps>`
  background-color: ${({ type, theme }) => lighten(0.4, theme.color[type])};
  border: 1px solid ${({ type, theme }) => lighten(0.1, theme.color[type])};
  color: ${({ type, theme }) => theme.color[type]};
  padding: ${({ theme }) => theme.padding.md};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  margin-bottom: ${({ theme }) => theme.margin.md};
`;
