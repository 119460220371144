import React, { Component } from 'react'
import { getAccounts, Account } from '../api/accountApi'
import Grid from '../components/grid/Grid'
import H1 from '../components/typography/H1'

interface AccountsState {
  accounts: Account[]
}

class Accounts extends Component<{}, AccountsState> {
  state = {
    accounts: []
  }

  componentDidMount = () => {
    getAccounts()
      .then(res => {
        this.setState({ accounts: res.data })
      })
  }

  render = () => (
    <div>
      <H1>Accounts</H1>
      <Grid
        actions={[
          { label: 'Edit', route: '/accounts/:id' }
        ]}
        columns={[
          {
            Header: 'ID',
            accessor: 'id'
          },
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Domain',
            accessor: 'domain'
          }
        ]}
        data={this.state.accounts}
      />
    </div>
  )
}

export default Accounts
