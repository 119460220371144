import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProducts, Product } from '../api/productApi';
import { AxiosResponse } from 'axios';
import Grid from '../components/grid/Grid';
import { AppState } from '../redux/reducers';
import {
  getActiveAccountId,
  getActiveAccountName,
} from '../selectors/envSelectors';
import PageHeader from '../components/layout/PageHeader';

interface AccountProductsProps {
  accountId: string | null;
  accountName: string;
}

interface AccountProductsState {
  products: Product[];
  loading: boolean;
}

class AccountProducts extends Component<
  AccountProductsProps,
  AccountProductsState
> {
  state = {
    products: [],
    loading: true,
  };

  componentDidMount = () => {
    this.fetchProducts();
  };

  componentDidUpdate = (prevProps: AccountProductsProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.setState({ loading: true }, () => this.fetchProducts());
    }
  };

  fetchProducts = () => {
    if (this.props.accountId) {
      getProducts(this.props.accountId).then((res: AxiosResponse) => {
        this.setState({ products: res.data.items, loading: false });
      });
    }
  };

  render = () => (
    <div>
      <PageHeader>Products</PageHeader>
      <Grid
        isLoading={this.state.loading}
        actions={[
          { label: 'Edit', route: `/${this.props.accountName}/products/:id` },
        ]}
        columns={[
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Price',
            accessor: 'price',
          },
          {
            Header: 'From price',
            accessor: 'from_price',
          },
          {
            Header: 'Source',
            accessor: 'source',
          },
          {
            Header: 'Last synced',
            accessor: 'synced_at',
          },
        ]}
        data={this.state.products}
      />
    </div>
  );
}

const mapState = (state: AppState) => ({
  accountName: getActiveAccountName(state),
  accountId: getActiveAccountId(state),
});

export default connect(mapState)(AccountProducts);
