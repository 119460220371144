import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShimmerRect from './ShimmerRect';

const SkeletonRow = styled('div')`
  box-shadow: inset 0 -1px 0 0 rgba(0,0,0,.12);
  display: flex;
`;

const TableSkeleton = ({ rows }) => (
  <>
    {[...Array(rows || 10).keys()].map((key) => (
      <SkeletonRow key={key}>
        <ShimmerRect width={16} height={14} margin="1.2rem" />
        <ShimmerRect width={50} height={14} margin="1.2rem 2rem" />
        <ShimmerRect width={300} height={14} margin="1.2rem 1rem" />
        <ShimmerRect width={110} height={14} margin="1.2rem 5rem" />
        <ShimmerRect width={60} height={14} margin="1.2rem 1rem" />
        <ShimmerRect width={180} height={14} margin="1.2rem 1rem" />
      </SkeletonRow>
    ))}
  </>
);

TableSkeleton.propTypes = {
  rows: PropTypes.number
};

export default TableSkeleton;
