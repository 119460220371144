import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getOffers, Offer } from '../api/offerApi'
import { AxiosResponse } from 'axios'
import Grid from '../components/grid/Grid'
import { AppState } from '../redux/reducers'
import { getActiveAccountId, getActiveAccountName } from '../selectors/envSelectors'
import PageHeader from '../components/layout/PageHeader'
import { RouteComponentProps } from 'react-router-dom'

interface AccountOffersProps extends RouteComponentProps {
  accountId: string | null;
  accountName: string;
}

interface AccountOffersState {
  clearingCache: boolean
  offers: Offer[];
  loading: boolean;
}

class AccountOffers extends Component<AccountOffersProps, AccountOffersState> {
  state = {
    clearingCache: false,
    offers: [],
    loading: true,
  };

  componentDidMount = () => {
    this.fetchOffers()
  }

  componentDidUpdate = (prevProps: AccountOffersProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.setState({ loading: true }, () => this.fetchOffers())
    }
  }

  fetchOffers = () => {
    if (this.props.accountId) {
      getOffers(this.props.accountId)
        .then((res: AxiosResponse) => {
          this.setState({ offers: res.data.items, loading: false })
        })
    }
  }

  render = () => (
    <div>
      <PageHeader
        actions={[
          {
            key: 'create',
            label: 'New offer',
            onClick: () => this.props.history.push(`/${this.props.accountName}/offers/create`)
          }
        ]}
      >
        Offers
      </PageHeader>
      <Grid
        isLoading={this.state.loading}
        actions={[
          { label: 'Edit', route: `/${this.props.accountName}/offers/:id` }
        ]}
        columns={[
          {
            Header: 'Slug',
            accessor: 'slug'
          },
          {
            Header: 'Title',
            accessor: 'name'
          },
          {
            Header: 'Product name',
            accessor: 'product_name'
          },
          {
            Header: 'Vendor name',
            accessor: 'vendor.name'
          },
          {
            Header: 'Offer Id',
            accessor: 'id'
          },
          {
            Header: 'Creation date',
            accessor: 'created_at'
          }
        ]}
        data={this.state.offers}
      />
    </div>
  )
}

const mapState = (state: AppState) => ({
  accountName: getActiveAccountName(state),
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(AccountOffers)
