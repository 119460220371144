import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../redux/reducers'
import { getActiveAccountId } from '../selectors/envSelectors'
import Row from '../components/layout/Row'
import Col from '../components/layout/Col'
import PageHeader from '../components/layout/PageHeader'
import { Order, getOrder, Log } from '../api/orderApi'
import Grid from '../components/grid/Grid'
import Card from '../components/layout/Card'
import H3 from '../components/typography/H3'
import moneyUtils from '../utils/moneyUtils'
import dateUtils from '../utils/dateUtils'
import Loader from '../components/loaders/Loader'

interface RouteParams {
  id: string
}

interface AccountOrdersDetailProps extends RouteComponentProps<RouteParams> {
  accountId: string | null
}

interface AccountOrdersDetailState {
  order?: Order
}

class AccountOrdersDetail extends Component<AccountOrdersDetailProps, AccountOrdersDetailState> {
  state: AccountOrdersDetailState = {}

  componentDidMount = () => {
    this.fetchOrder()
  }

  componentDidUpdate = (prevProps: AccountOrdersDetailProps) => {
    if (this.props.accountId && prevProps.accountId !== this.props.accountId) {
      this.fetchOrder()
    }
  }

  fetchOrder = () => {
    if (typeof this.props.match.params.id !== 'undefined' && this.props.accountId) {
      getOrder(this.props.accountId, this.props.match.params.id)
        .then((res) => {
          this.setState({ order: res.data })
        })
    }
  }

  render = () => {
    const { order } = this.state
    if (typeof order === 'undefined') {
      return <Loader />
    }

    return (
      <div>
        <PageHeader>
          Order #{order.order_number}
        </PageHeader>
        <Row>
          <Col col={6}>
            <H3>Personal details</H3>
            <Card>
              <Row>
                <Col col={4}>Name</Col>
                <Col col={8}>{order.customer.first_name} {order.customer.last_name}</Col>
              </Row>
              <Row>
                <Col col={4}>Email</Col>
                <Col col={8}>{order.customer.email}</Col>
              </Row>
              <Row>
                <Col col={4}>Phone Number</Col>
                <Col col={8}>{order.customer.phone || '-'}</Col>
              </Row>
              <Row>
                <Col col={4}>Newsletter</Col>
                <Col col={8}>{order.customer.newsletter ? 'Yes' : 'No'}</Col>
              </Row>
              <Row>
                <Col col={4}>Created at</Col>
                <Col col={8}>{dateUtils.toDateTimeLabel(order.created_at)}</Col>
              </Row>
            </Card>
          </Col>
          <Col col={6}>
            <H3>Payment details</H3>
            <Card>
              <Row>
                <Col col={4}>Total amount</Col>
                <Col col={8}>{moneyUtils.toLabel(order.payment.amount)}</Col>
              </Row>
              {order.payment.amount > 0 && (
                <>
                  <Row>
                    <Col col={4}>Ingenico payment status</Col>
                    {typeof order.payment.status !== 'undefined' && (
                      <Col col={8}>
                        {order.payment.status}
                        &nbsp;(<a href="https://epayments-support.ingenico.com/en/get-started/payment-platform-user-guides/transaction-statuses/guide" target="_blank">Read more</a>)
                      </Col>
                    )}
                    {typeof order.payment.status === 'undefined' && (
                      <Col col={8}>
                        Not paid (yet)
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col col={4}>Ingenico payment ID</Col>
                    <Col col={8}>{order.payment_id}</Col>
                  </Row>
                </>
              )}
            </Card>
          </Col>
        </Row>
        <H3>Products</H3>
        <Grid
          actions={[]}
          columns={[
            { Header: 'Product', accessor: 'name' },
            { Header: 'Quantity', accessor: 'quantity' },
          ]}
          data={order.products}
        />
        <Row>
          <Col col={6}>
            <H3>Events</H3>
            <Grid
              actions={[]}
              columns={[
                { Header: 'Event', accessor: 'message' },
                {
                  Header: 'At',
                  accessor: (row) => {
                    const log = (row as Log)
                    return dateUtils.toDateTimeLabel(log.created_at)
                  }
                }
              ]}
              data={order.logs || []}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  accountId: getActiveAccountId(state)
})

export default connect(mapState)(AccountOrdersDetail)
